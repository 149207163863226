/* Login.css */

.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.form {
    width: 300px;
    padding: 20px;
    background: #f0f0f0;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.login-with-google-btn {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background: #4285f4;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.login-with-google-btn:hover {
    background: #357ae8;
}
