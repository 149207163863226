.fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1030;
}
.search-results {
  position: absolute;
  background-color: #ffffff; /* White background for the results */
  border: 1px solid #ddd; /* Border around the results */
  border-radius: 4px; /* Rounded corners */
  max-height: 300px; /* Maximum height for scrolling */
  overflow-y: auto; /* Scroll if there are too many results */
  width: 100%; /* Make the results container the same width as the input */
  z-index: 1000; /* Ensure the results are above other content */
}

.search-result-item {
  font-size: 13px;
  display: block;
  padding: 10px;
  color: #333; /* Text color */
  text-decoration: none; /* Remove underline from links */
  border-bottom: 1px solid #ddd; /* Divider between items */
}

.search-result-item:last-child {
  border-bottom: none; /* Remove border for the last item */
}

.search-result-item:hover,
.search-result-item:focus {
  background-color: #f1f1f1; /* Highlight on hover */
  color: #0094db; /* Text color on hover */
}

.search-results div {
  padding: 10px;
  color: #666; /* Text color for "No results found" */
  text-align: center;
}

body {
  /* padding-top: 70px; */
  font-family: "Roboto", sans-serif;
}

.navbar-custom {
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid #ddd;
}
.Logo {
  height: 40px;
  margin: 10px 0px 10px 24px;
}
.navbar-nav {
  gap: 50px;
  justify-content: center;
  width: 100%;
  /* margin: 39px 0px; */
}

.nav-item {
  color: #163365;

  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  opacity: 1;
}

.contact-button-container {
  gap: 20px;
  display: flex;
  align-items: center;
}

.search-icon {
  color: #666666;
  /* margin-right: 10px; */
  font-size: 20px;
}
.contact-button-container {
  position: relative;
}

.search-container {
  display: flex;
  align-items: center;
  position: relative;
}

.search-icon {
  cursor: pointer;
  position: absolute;
  right: 10px;
  z-index: 1;
}

.search-icon svg {
  height: 20px;
  width: 20px;
}

.categorysection div:nth-child(3) button.btn {
  white-space: inherit !important;
}

.categorysection {
  overflow: hidden; /* Prevents content from overflowing */
}


.categorysection .ms-3 {
  max-width: 100%; /* Ensures the subcategories fit within the parent container */
  word-wrap: break-word; /* Breaks long words to avoid overflow */
}

.blogdate {
  text-align: left;
  font-size: 11px;
}
 
button {
  white-space: normal; /* Allows text wrapping if needed */
  overflow: hidden; /* Hides overflowing text */
  text-overflow: ellipsis; /* Adds ellipsis for text overflow */
}

.blogwrapper .card-body{ background: #f9f9f9;}


 
.subcategory-container {
  border: 1px solid #ccc; /* Adds a border to the subcategory container */
  padding: 5px; /* Adds padding inside the container */
  background-color: #f9f9f9; /* Light background for better visibility */
  border-radius: 4px; /* Rounded corners */
}
has context menu
.search-input {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 14px;
  width: 0;
  opacity: 0;
  visibility: hidden;
  /* position: absolute; */
  right: 0;
  transition: all 0.3s ease-in-out;
}

.search-container-visible .search-input {
  width: 150px; /* Adjust as needed */
  opacity: 1;
  visibility: visible;
}

.contact-button {
  border-radius: 4px;
  outline: none !important;
  padding: 6px 15px;
  height: 30px;
  font-size: 14px;
  margin: 35px 24px 35px 0px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  background: #0094db 0% 0% no-repeat padding-box;
  opacity: 1;
}
.contact-button:hover {
  background-color: #f26836;
  color: #fff;
}

.contact-button:focus,
.contact-button:active {
  background-color: #0094db !important;
  color: #ffffff;
  box-shadow: none; /* Remove any default focus outline */
}
.dropdown-submenu {
  position: relative;
}
.dropdown-menu {
  position: absolute;
  display: none;
  top: 100%; /* Adjust based on your navbar's height */
  left: 0;
  z-index: 1000; /* Ensure dropdown is above other content */
}
.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: 0;
  display: none;
}

.dropdown-submenu:hover .dropdown-menu {
  display: block;
}

.dropdown-menu.show {
  display: block;
}

.NavTab {
  margin-right: 80px;
}

.navbar-custom {
  /* height: 100px; */
  height: 60px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.nav-link.nav-link-hover {
  padding: 0px !important;
}
.nav-item.dropdown.active-dropdown > a {
  border-bottom: 2px solid #007bff; /* Underline style for active dropdown */
  font-weight: bold;
}
/* General hover and focus styles for nav items */
.nav-item:hover,
.nav-item:focus {
  color: #0094db;
}

/* Styling for nav links */
.nav-link-hover {
  position: relative;
}

/* Line for hover state */
.nav-link-hover::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -4px;
  left: 0;
  background-color: #0094db;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.nav-link.active,
.navbar-nav .nav-link.active {
  background-color: #fff !important;
  color: #0094db;
  font-weight: bold;
}
.nav-link.active,
.navbar-nav .nav-link.active {
  background-color: #fff !important;
  color: #0094db;
  font-weight: bold;
}

/* Line for hover effect */
.nav-link-hover:hover::after,
.nav-link-hover:focus::after {
  transform: scaleX(1);
}

/* Styling for the active nav link */
.nav-link-hover.active::after {
  transform: scaleX(1);
  /* Additional styles for active link can be added here */
}

.nav-link-hover:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.navbar-nav .nav-link {
  color: #163365;
}

.navbar-nav .nav-link:hover,
.navbar-nav .nav-link:focus {
  color: #0094db;
  text-decoration: underline;
}

.dropdown-menu .nav-item:hover,
.dropdown-menu .nav-item:focus {
  background-color: #f1f1f1;
}

.navbar-custom .nav-item .nav-link:hover,
.navbar-custom .nav-item .nav-link:focus {
  background-color: transparent;
  color: #0094db;
}

.nav-item:hover,
.nav-item:focus {
  color: #0094db;
  text-decoration-color: #0094db;
}

.navbar-nav .nav-link {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #163365;
  text-decoration: none;
  margin: 19px 0px;
}

.navbar-nav .nav-link:hover,
.navbar-nav .nav-link:focus {
  color: #0094db;
  text-decoration: none;
}

.navbar-toggler {
  border: 1px solid black;
  padding: 0.25rem 0.75rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><path stroke="rgba(0, 0, 0, 1)" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 7h22M4 15h22M4 23h22"/></svg>');
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><path stroke="rgba(0, 0, 0, 1)" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M6 6l18 18M6 24L24 6"/></svg>');
}

.navbar-toggler[aria-expanded="false"] .navbar-toggler-icon {
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><path stroke="rgba(0, 0, 0, 1)" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 7h22M4 15h22M4 23h22"/></svg>');
}

.nav-item.dropdown:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.dropdown-item {
  font-size: 16px;
}
.dropdown-menu .dropdown-item:hover {
  text-decoration: none;
  background-color: #0094db;
  color: #ffffff;
}

.dropdown-icon,
.dropdown-icons {
  display: inline-block;
  /* height: 20px;
  width: 20px; */
  vertical-align: middle;
  transition: transform 0.3s ease;
}

.nav-item.dropdown:hover .dropdown-icon {
  transform: rotate(180deg);
}

.dropdown-submenu:hover .dropdown-icons {
  transform: rotate(-90deg);
}

/* .collapse.navbar-collapse{
  margin-right: 30px;
} */
.search-icon svg {
  height: 20px;
  width: 20px;
  margin-top: 40px;
  margin-bottom: 40px;
}
@media (max-width: 992px) {
  .search-container {
    position: relative;
  }


  .search-icon {
    margin-bottom: 10px;
  }
}
/* @media (max-width: 991px) {
  .navbar-custom {
    display: flex;
    justify-content: space-between; 
    align-items: center;
  }

  .navbar-brand {
    margin-right: 0; 
  }

  .navbar-toggler {
    margin-left: 0; 
  }

  .contact-button-container {
    width: auto;
    margin-right: 0;
    padding: 10px;
    justify-content: flex-end;
  }

  .search-icon {
    margin-bottom: 0;
  }
} */

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    /* gap: 30px; */
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    top: 64px !important;
  }

  .navbar-expand-lg .navbar-nav .dropdown-submenu .dropdown-menu {
    top: 0px !important;
  }
}
.nav-link.active,
.navbar-nav .nav-link.active {
  background-color: #fff !important;
  color: #0094db;
  font-weight: bold;
}

.dropdown-menu .dropdown-item.active {
  background-color: #0094db;
  color: #ffffff;
}
.search-icon svg {
  height: 20px;
  width: 20px;
  margin-top: 40px;
  margin-bottom: 40px;
}
@media (max-width: 600px) {
  .dropdown-submenu {
    position: inherit !important;
  }
}
@media (max-width: 600px) {
  .but1,
  .buttons {
    height: 40px !important;
    font-size: 18px !important;
    padding: 8px 12px !important;
    margin-left: 8px !important;
  }
}
@media (max-width: 991px) {
  .navbar-custom {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .navbar-brand {
    margin-right: 0;
  }

  .navbar-toggler {
    margin-left: 0;
  }

  .contact-button-container {
    width: auto;
    margin-right: 0;
    /* padding: 0px 0px 0px 20px; */
  }

  .search-icon {
    margin-bottom: 0;
  }
}

/* @media (min-width: 992px) {

 
    .navbar-expand-lg .navbar-nav .dropdown-menu {
   
        top: 65px !important;
    }

    .navbar-expand-lg .navbar-nav .dropdown-submenu .dropdown-menu{   top: 0px !important; }
} */
.nav-link.active,
.navbar-nav .nav-link.active {
  background-color: #fff !important;
  color: #0094db;
  font-weight: bold;
}

.dropdown-menu .dropdown-item.active {
  background-color: #0094db;
  color: #ffffff; /* Ensure text color is white */
}
@media (max-width: 600px) {
  .navbar-nav {
    gap: 0px;
  }
  .navbar-nav .nav-link {
    margin: 9px 0px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .dropdown-submenu {
    position: static;
  }
  .navbar-custom {
    height: auto; /* Adjust height as needed */
    padding: 10px 20px;
  }

  .navbar-nav {
    gap: 20px;
  }

  .navbar-nav .nav-item {
    margin: 0;
  }

  .navbar-nav .nav-link {
    font-size: 16px;
    margin: 0 10px;
  }

  .search-container {
    display: flex;
    align-items: center;
    position: relative;
  }

  .search-container-visible .search-input {
    width: 150px; /* Adjust as needed */
    opacity: 1;
    visibility: visible;
  }

  .contact-button-container {
    width: auto;
    margin: 0;
    justify-content: flex-start;
  }

  .dropdown-menu {
    top: 100%; /* Adjust based on your navbar's height */
  }

  .dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
    display: none;
  }

  .dropdown-submenu:hover .dropdown-menu {
    display: block;
  }

  .navbar-toggler-icon {
    background-size: 100%;
  }

  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><path stroke="rgba(0, 0, 0, 1)" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M6 6l18 18M6 24L24 6"/></svg>');
  }

  .navbar-toggler[aria-expanded="false"] .navbar-toggler-icon {
    background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><path stroke="rgba(0, 0, 0, 1)" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 7h22M4 15h22M4 23h22"/></svg>');
  }

  .navbar-nav .nav-link.active {
    background-color: #fff !important;
    color: #0094db;
    font-weight: bold;
  }

  .dropdown-menu .dropdown-item.active {
    background-color: #0094db;
    color: #ffffff;
  }
  /* .contact-button-container{
    display: none;
  } */
  .navbar-collapse {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .carousel-caption h1 {
    font-size: 55px !important;
  }
  .wwd h1 {
    font-size: 55px !important;
  }
}
@media (max-width: 992px) {
  .navbar-custom {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .navbar-brand {
    margin-right: 0;
  }

  .navbar-toggler {
    margin-left: 0;
  }

  .contact-button-container {
    width: auto;
    margin-right: 0;
    /* padding: 10px; */
    /* justify-content: flex-end; */
  }

  .search-icon {
    margin-bottom: 0;
  }
}
.overlay {
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(7, 44, 100, 0.5); /* Color #072c64 with 50% opacity */
  pointer-events: none; /* Allows clicks to pass through the overlay */
}

/* .container {
} */
@media (max-width: 1200px) {
  /* Laptop */
  .custom-prev {
    left: 30px !important;
  }

  .custom-next {
    right: 30px !important;
  }

  .carousel-heading {
    padding: 80px 0px 80px 200px !important; /* Adjust padding */
  }

  .carousel-heading h1 {
    font-size: 32px !important; /* Smaller font */
    line-height: 42px !important;
  }

  .carousel-heading p {
    font-size: 20px !important;
    line-height: 28px !important;
  }
}

@media (max-width: 992px) {
  /* Tablet */
  .custom-prev {
    left: 20px !important;
  }

  .custom-next {
    right: 20px !important;
  }

  .carousel-heading {
    padding: 60px 0px 60px 100px !important; /* Further reduce padding */
    text-align: center !important;
  }

  .carousel-heading h1 {
    font-size: 28px !important;
    line-height: 38px !important;
  }

  .carousel-heading p {
    font-size: 18px !important;
    line-height: 26px !important;
  }
}

@media (max-width: 768px) {
  /* iPad */
  .custom-prev {
    left: 15px !important;
  }

  .custom-next {
    right: 15px !important;
  }

  .carousel-heading {
    padding: 40px 0px 40px 50px !important; /* Adjust padding */
    text-align: center !important;
  }

  .carousel-heading h1 {
    font-size: 24px !important;
    line-height: 32px !important;
  }

  .carousel-heading p {
    font-size: 16px !important;
    line-height: 24px !important;
  }
}

@media (max-width: 576px) {
  /* Mobile phones */
  .custom-prev,
  .custom-next {
    font-size: 28px !important; /* Smaller icons */
  }

  .carousel-heading {
    top: 37% !important;
    padding: 20px 10px !important; /* Compact padding for mobile */
    text-align: center !important;
  }

  .carousel-heading h1 {
    font-size: 20px !important;
    line-height: 28px !important;
  }
  .client-logo {
    height: 70px !important;
    width: auto !important;
  }
  .carousel-heading p {
    font-size: 14px !important;
    line-height: 22px !important;
  }
  .text-start,
  .text-end {
    text-align: center !important;
  }
  .services1 .col-md-7 {
    padding: 20px 40px !important;
  }
}
@media (max-width: 768px) {
  .services1 .row {
    flex-direction: column; /* Stack the items vertically */
    align-items: center; /* Center align items */
  }

  .services1 .col-md-5,
  .services1 .col-md-7 {
    width: 100%; /* Make the columns take full width */
    text-align: center; /* Center text */
  }

  .services1 .img-fluids {
    max-width: 100%; /* Ensure the image scales down */
    height: auto; /* Maintain aspect ratio */
  }
}

.carousel-heading {
  position: absolute;
  top: 54%;
  left: 53%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  color: white;
}
.carousel-heading h1 {
  margin: 0px !important;
  font-weight: 300 !important;
  text-shadow: 0px 3px 6px #000000;
  font-family: "Roboto", sans-serif;
  font-size: 80px; /* Increase font size */
  color: #ffffff;
  line-height: 48px;
}
.carousel-heading p {
  font-family: "Roboto Slab", serif;
  text-shadow: 0px 3px 6px #000000;
  padding: 8px 0px 0px 0px;
  font-size: 24px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-weight: 400;
  margin: 0px !important;
  line-height: 32px;
}
.servicess {
  background-color: #f9f9f9;
}
.our-services {
  justify-content: center;
  padding: 100px 0px 30px 0px;
  /* padding-bottom: 50px; */
  /* display: flex; */
  align-items: center;
  text-align: center;
}
.our-services h1 {
  line-height: 43px;
  font-size: 36px;
  text-align: center;
  color: #000;
  opacity: 1;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  padding-bottom: 10px;
  margin: 0px !important;
}
.our-services p {
  line-height: 40px;
  font-size: 24px;
  color: #000;
  opacity: 1;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  margin: 0px !important;
}
.our-services a {
  padding: 0px 5px;
  color: #000;
  text-decoration: none;
}
.our-services a:hover {
  color: #f26836;
  text-decoration: underline;
}
h2 {
  font-size: 30px;
  color: #163365;
  font-weight: bold;
}

p {
  font-size: 16px;
  color: #333333;
}

button {
  background: #163365;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
}
.img-fluids {
  /* height: 250px; */
  width: 490px;
}
@media (max-width: 768px) {
  h2,
  p {
    text-align: center;
  }
  .article-header img {
    width: 100%;
  }
}

.services1 img {
  border-radius: 6px;
  /* height: 250px; */
  width: 100%;
}
.services1 .col-md-7 {
  padding: 0px 40px;
}
.services1 h2 {
  margin: 0px !important;
  font-weight: 500px;
  font-family: "Roboto", sans-serif;
  font-size: 36px; /* Increase font size */
  color: #000;
  line-height: 48px;
}
.services1 p {
  margin: 0px !important;
  font-weight: 400px;
  font-family: "Roboto", sans-serif;
  font-size: 16px; /* Increase font size */
  color: #000;
  line-height: 24px;
  padding-top: 10px;
  padding-bottom: 20px;
}
.service-button {
  background: #eeeeee 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  font-size: 16px;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.image-wrapper {
  width: 100%;
  overflow: hidden;
}

.image-wrapper img {
  width: 100%;
  height: auto;
  display: block;
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white; /* Adjust based on your image */
  text-align: center;
  z-index: 10;
  padding: 20px;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Optional: semi-transparent background for better readability */
  border-radius: 8px; /* Optional: rounded corners */
}

.text-overlay h2 {
  font-size: 2em; /* Adjust font size as needed */
  margin-bottom: 10px;
}

.text-overlay p {
  font-size: 1em; /* Adjust font size as needed */
  margin: 0;
}
.carousel-item img {
  width: 100%;
  height: 800px; /* Adjust height as needed */
  object-fit: cover;
}

.carousel-caption {
  position: absolute;
  top: 50%;
  left: 10%; /* Adjusted to align text to the left */
  transform: translateY(-50%);
  text-align: left; /* Align text to the left */
  color: white;
}

.carousel-caption h1 {
  margin: 0px !important;
  font-weight: 500px;
  text-shadow: 0px 3px 6px #000000;
  font-family: "Roboto", sans-serif;
  font-size: 84px; /* Increase font size */
  color: #ffffff;
}
/* Hide the text of the carousel controls */
.carousel-control-next,
.carousel-control-prev {
  /* Hide any text content */
  text-indent: -9999px;
}

/* Ensure only the icons are displayed */
.carousel-control-next-icon,
.carousel-control-prev-icon {
  /* Ensure the icons are visible */
  background-size: 100%, 100%;
  background-repeat: no-repeat;
}

/* Optional: adjust the size and position of the icons if needed */
.carousel-control-next,
.carousel-control-prev {
  width: 5%; /* Adjust as needed */
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-caption p {
  font-family: "Roboto Slab", serif;
  text-shadow: 0px 3px 6px #000000;
  margin: 8px 0px 30px 0px;
  font-size: 36px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-weight: 400;
}

.button-container .btn {
  /* border: 1px solid; */
  font-weight: 400;
  font-size: 18px;
  padding: 18px 30px 17px 30px;
  text-align: left;
  height: 61px;
  text-shadow: 0px 3px 6px #000000;
  opacity: 1;
  font-family: "Roboto", sans-serif;
}
.button-container .btn :hover {
  text-shadow: none !important;
}
.but1 {
  font-weight: 400;
  font-size: 20px;
  padding: 18px 30px;
  text-align: left;
  height: 61px;
  /* text-shadow: 0px 3px 6px #000000; */
  opacity: 1;
  font-family: "Roboto", sans-serif;
  background-color: transparent;
  border: 1px solid #fff;
  text-decoration: none;
  color: #fff;
}
.buttons {
  font-weight: 400;
  font-size: 20px;
  padding: 18px 30px;
  text-align: left;
  height: 61px;
  margin-left: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  text-shadow: none !important;
  color: black;
  font-family: "Roboto", sans-serif;
  border: none;
}
.but1:hover {
  text-decoration: none !important;
  border: none;
  background-color: #0094db;
  color: #fff;
}
.buttons:hover {
  background-color: #0094db;
  color: #fff;
}

.transparent-button {
  background-color: transparent;
  border: 2px solid white;
  color: white;
}

.transparent-button:hover,
.transparent-button:focus,
.transparent-button:active {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border-color: white;
}

.white-button {
  background-color: white;
  color: black;
  border: none;
}

.white-button:hover,
.white-button:focus,
.white-button:active {
  background-color: white;
  color: black;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: none;
}
.carousel-indicators {
  display: none;
}

@media (max-width: 600px) {
  .carousel-caption h1 {
    font-size: 45px;
  }

  .carousel-caption p {
    font-size: 24px;
  }

  .button-container .btn {
    font-size: 1rem;
    padding: 8px 16px;
  }
}

@media (max-width: 576px) {
  .carousel-caption h1 {
    font-size: 35px; /* Further decrease font size for very small screens */
  }

  .carousel-caption p {
    font-size: 18px; /* Further decrease font size for very small screens */
  }

  .button-container .btn {
    height: 31px;
    font-size: 0.875rem; /* Adjust button font size */
    padding: 6px 12px; /* Adjust button padding */
  }
}
@media (min-width: 1800px) {
  .carousel-caption {
    padding-left: 115px; /* Adjust this value as needed */
  }
}
.collapse.navbar-collapse.show {
  background-color: #fff;
}
@media (max-width: 1400px) {
  .carousel-heading h1 {
    font-size: 60px !important;
  }
  /* .carousel-heading{
    padding: 126px 0px 107px 290px !important;
  } */
  .select-style {
    padding: 0px 30px 0px 6px;
  }
  .carouselimg {
    height: 300px;
  }
}
.active > .page-link,
.page-link.active {
  color: #fff;
}
@media (max-width: 992px) {
  h1 {
    font-size: 60px;
  }
  .carouselimg {
    height: 250px;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 40px !important;
  }
  .carouselimg {
    height: 200px;
  }
}

@media (max-width: 576px) {
  .carousel-heading {
    padding: 0px !important;
    font-size: 35px !important;
  }
  .carousel-heading h1 {
    margin-left: 0%;
    /* left: 50%; */
    margin-top: 40% !important;
    font-size: 35px !important;
    padding: 42px !important;
  }
  .client-heading {
    margin: 0px !important;
  }
  .carouselimg {
    height: 150px;
  }
}
/* What we do Session CSS
.img {
  max-width: 100%;
  padding: 10px 10px 0px 10px;
}
.wwd1 {
  padding: 60px 0;
}
.wwd {

  margin-top: 100px;
  padding: 20px;
  border-radius: 10px;
  color: #000000;
}
.wwd h1 {
  font-weight: 700;
  text-align: center;
  font-family: "Roboto Slab", serif;
  letter-spacing: 0px;
  color: #163365;
  opacity: 1;
  margin-bottom: 50px;
  font-size: 72px;
}
.card {
  border: 1px solid #73839F;
opacity: 1;
}
.card-body{
  padding: 0px !important;
}
.card-title {
  text-align: center;
  font: normal normal medium 30px/39px Roboto Slab;
  letter-spacing: 0px;
  color: #163365;
  opacity: 1;
}
.card{
  max-width: 100%;
}
.card-text {
  text-align: center;
  font: normal normal normal 18px/28px Roboto;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  overflow: hidden;
  max-height: 80px;
  position: relative;
  transition: max-height 0.2s ease;
}
.card-text.expanded {
  max-height: 200px;
}
.dots {
  color: #333333;
}
.more {
  display: inline;
}
.btn-container {
  margin-bottom: 50px;
  text-align: center;
  margin-top: 30px;
}

.wwd .card-title{ font-size: 30px;   font-family: "Roboto Slab", serif;
}
.custom-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #f26836;
  color: #f26836;
  opacity: 1;
  padding: 10px 20px;
  font-size: 1rem;
}
.btn-primary:hover,
.btn-primary:focus {
  background-color: #f26836;
  color: #ffffff;
  outline: none;
} */
/* General Styles */
/* .container {
  max-width: 1320px;
} */

.img {
  width: 100%;
  padding: 10px 10px 0px 10px;
}

.wwd1 {
  padding: 60px 0;
}

.wwd {
  border-radius: 10px;
  color: #000000;
}

.wwd h1 {
  padding-top: 100px;
  font-weight: 700;
  text-align: center;
  font-family: "Roboto Slab", serif;
  letter-spacing: 0px;
  color: #163365;
  opacity: 1;
  margin-bottom: 50px;
  font-size: 72px;
}
.wwd .row {
  display: flex;
  flex-wrap: wrap;
}

.wwd .col-md-3 {
  display: flex;
  margin-bottom: 20px; /* Add spacing between rows */
}

.wwd .card {
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid #73839f;
  opacity: 1;
  width: 100%;
}

.wwd .card-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding: 0px !important;
}

.card-title {
  text-align: center;
  font-family: "Roboto Slab", serif;
  letter-spacing: 0px;
  color: #163365;
  opacity: 1;
  white-space: nowrap;
  font-size: 30px;
  padding-top: 28px;
}

.card-text {
  margin: 0px !important;
  font-weight: 400;
  font-size: 18px;
  padding: 20px 20px 0px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  overflow: hidden;
  max-height: 160px;
  position: relative;
  transition: max-height 0.2s ease;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  line-height: 28px;
}

.btn-container {
  padding-top: 30px !important;
  padding-bottom: 50px;
  text-align: center;
  /* margin-top: 30px; */
}

.custom-button {
  font-weight: 400;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #f26836 !important;
  color: #f26836 !important;
  opacity: 1;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  outline: none !important;
}

.custom-button:focus,
.custom-button:active {
  outline: none;
  border: none;
}

.custom-button:hover {
  background-color: #f26836 !important;
  color: #fff !important;
  border: 1px solid #f26836;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .wwd .col-md-3 {
    flex: 1 1 50%; /* Two cards per row */
    max-width: 50%;
  }

  .wwd .card {
    margin-bottom: 20px; /* Adjust spacing between cards */
  }

  .wwd h1 {
    font-size: 48px; /* Adjust heading size for smaller screens */
  }

  .card-title {
    font-size: 24px; /* Adjust card title size */
  }

  .card-text {
    font-size: 16px; /* Adjust card text size */
    max-height: none; /* Allow text to expand fully */
    white-space: normal; /* Allow multiline text */
  }
  .new-text h1 {
    font-size: 55px !important;
  }
  .header {
    font-size: 55px !important;
  }
  .container.Journey h1 {
    font-size: 55px !important;
  }
  .client-heading {
    font-size: 55px !important;
  }
  .contact-section .col-md-8 {
    display: none;
  }
  .contact-section .col-md-4 {
    flex: 0 0 100%; /* Full width for the form container on iPad */
    max-width: 100%;
  }
  .select-style {
    padding: 0px 0px 0px 4px !important;
  }
  .contact-header {
    font-size: 55px !important;
    text-align: center !important;
  }
  .footer-section p {
    font-size: 12px;
  }
  .footer-section1 h4 {
    font-size: 14px !important;
  }
  .list-unstyled {
    padding-top: 10px !important;
  }
  .footer-section1 ul li a {
    font-size: 10px !important;
  }
  .carousel-caption p {
    font-size: 25px;
  }
  .but1,
  .buttons {
    height: auto !important;
    font-size: 16px !important;
    padding: 14px 25px !important;
  }
}
.footer-section1 {
  padding-left: 40px;
}
.btn:hover {
  background-color: #0094db;
  color: #fff;
}
.map {
  /* background-image: url("./Images/map.png"); */
  background-size: cover; /* You can try contain if cover doesn't work well */
  background-position: center;
  background-repeat: no-repeat;
  max-width: 100%;
  /* Optional: background-attachment: fixed; */
}
.map img {
  width: 100%;
}
/* Responsive styles */
@media (max-width: 768px) {
  .map {
    height: 30vh;
    background-size: contain;
  }
}

@media (max-width: 480px) {
  .map {
    background-size: contain;
  }
}
.button {
  font-size: 18px;
  padding: 12px 24px;
  margin: 0 10px;
  background-color: white;
  color: black;
  border: 1px solid white;
}

.dots,
.dots1,
.dots2,
.dots3 {
  color: #333333;
  position: absolute;
  /* background-color: white; */
}
/* .dots{
  right: 21px;
  bottom: 1px;
} */
.dots1 {
  right: 43px;
  bottom: 1px;
}
.dots2 {
  right: 42px;
  bottom: 1px;
}
.dots3 {
  right: 62px;
  bottom: 1px;
}
.container.Journey {
  margin-top: 100px;
}

.container.Journey h1 {
  font-family: "Roboto", serif;
  font-weight: 500;
  font-size: 36px;
  color: #000;
  text-align: center;
  padding-bottom: 50px;
}

.container.Journey img {
  background: #c8ecff 0% 0% no-repeat padding-box;
  opacity: 1;
  max-width: 100%;
}

/* .btn-primary:hover,
.btn-primary:focus {
  background-color: #f26836;
  color: #ffffff;
  outline: none;
} */

/* Adjust card spacing for iPad and mobile views */
@media (max-width: 600px) {
  .wwd .row {
    margin-left: 0;
    margin-right: 0;
  }

  .wwd .col-md-3 {
    margin-bottom: 20px;
  }
}

@media (max-width: 500px) {
  .wwd .col-md-3 {
    margin-bottom: 20px;
  }
  .wwd h1 {
    padding-top: 50px;
    font-size: 35px;
  }
  .wwd .card-title {
    font-size: 25px;
  }
  .container.Journey {
    margin-top: 50px;
  }
  .container.Journey h1 {
    font-size: 35px;
  }
  .footer p a::after {
    left: 7px;
    bottom: 0px;
    width: auto !important;
    height: 1px;
    background-color: #333;
    transition: width 0.3s ease;
  }
}

/* our client */
.image-container {
  padding: 0;
  margin: 0;
}

.image-container img {
  width: 100%;
  height: auto;
  display: block;
}

header {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 20px 0;
}

header h1,
header h2 {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

header h1 {
  font-size: 2.5rem;
}

header h2 {
  font-size: 1.5rem;
}

/* Why Bestowal */
/* .img-fluid {
  max-width: 100%;
  padding: 10px 10px 0px 10px;
}

.new-section {
  padding: 4rem 0;
  background-color: #f5f5f5;
}

.new-text {
  margin-top: 100px;
  margin-bottom: 2rem;
}

.new-text h1 {
  margin-bottom: 20px;
  text-align: center;
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  letter-spacing: 0px;
  color: #163365;
  opacity: 1;
  font-size: 72px;
}

.card-description {
  text-align: center;
  font-family: "Roboto Slab", serif;
  letter-spacing: 0px;
  color: #163365;
  font-size: 30px;
  opacity: 1;
  margin-bottom: 50px;
}

.card-title {
  text-align: center;
  font-family: "Roboto Slab", serif;
  letter-spacing: 0px;
  color: #163365;
  opacity: 1;
  font-size: 30px !important;
}

.card-descriptions {
  padding: 20px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  overflow: hidden;
  max-height: 115px;
  position: relative;
  transition: max-height 0.2s ease;
}

.custom-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #f26836;
  color: #f26836;
  opacity: 1;
  padding: 10px 20px;
  font-size: 1rem;
}

.custom-button:hover {
  background-color: #f26836;
  color: #fff;
  border-color: #0062cc;
}

.custom-button .fa-arrow-right {
  margin-left: 8px;
}

.container {
  max-width: 1320px;
}

.d-flex {
  display: flex;
}

.flex-grow-1 {
  flex-grow: 1;
} */
.container {
  max-width: 1320px;
}

.img-fluid {
  max-width: 100%;
  /* padding: 10px 10px 0px 10px; */
}

.new-section {
  padding: 4rem 0;
  background-color: #f5f5f5;
}


.new-text h1 {
  margin-top: 100px;
  margin-bottom: 20px;
  text-align: center;
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  letter-spacing: 0px;
  color: #163365;
  opacity: 1;
  font-size: 72px;
}
.new-text .card {
  border: 1px solid #73839f;
}
.card-description {
  font-weight: 400;

  text-align: center;
  font-family: "Roboto Slab", serif;
  letter-spacing: 0px;
  color: #163365;
  font-size: 30px;
  opacity: 1;
  margin-bottom: 50px;
}

.card-title {
  margin: 0px !important;
  font-weight: 400;
  text-align: center;
  font-family: "Roboto Slab", serif;
  letter-spacing: 0px;
  color: #163365;
  opacity: 1;
  font-size: 28px !important;
  padding-top: 30px;
}

.card-descriptions {
  margin: 0px !important;
  font-weight: 400;
  font-size: 18px;
  padding: 20px 20px 58px 20px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  overflow: hidden;
  max-height: 190px;
  position: relative;
  transition: max-height 0.2s ease;
  line-height: 28px;
  min-height: 100px; /* Ensure consistent height for card descriptions */
}
.material-symbols-outlined {
  margin-top: 3px;
  font-size: 18px;
  height: 18px !important;
  width: 18px !important;
}
.custom-button {
  gap: 10px;
  text-align: center;
  display: inline-flex;
  height: 40px;
  font-size: 18px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #f26836;
  color: #f26836;
  opacity: 1;
  padding: 8px 20px;
}

.custom-button:hover {
  background-color: #f26836;
  color: #fff;
  border-color: #0062cc;
}

.custom-button svg {
  color: #f26836;
  width: 18px;
  height: 18px;
  align-items: center;
  padding-bottom: 3px;
  margin-left: 8px;
}

.d-flex {
  display: flex;
}

.flex-grow-1 {
  flex-grow: 1;
}

.card-body {
  display: flex;
  flex-direction: column;
}

.mt-auto {
  margin-top: auto;
}

/* Adjust spacing between cards */
@media (max-width: 600px) {
  .col-md-4 {
    margin-bottom: 20px;
  }
  .card-title {
    font-size: 25px !important;
  }
  .new-text h1 {
    font-size: 35px;
  }
  .card-description {
    font-size: 25px;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .col-md-4 {
    margin-bottom: 30px;
  }
}

/* @media (max-width: 1800px) {
  .container {
    margin-left: 300px; 
  }
} */

/* Section4 */
.custom-section {
  padding: 100px 0;
}

.image-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-col .img-fluid {
  max-width: 100%;
}

.text-col {
  text-align: left;
}

.header {
  padding-bottom: 20px;
  margin: 0px !important;
  text-align: left;
  font-family: "Roboto Slab", serif;
  letter-spacing: 0px;
  color: #163365;
  opacity: 1;
  font-size: 60px;
  font-weight: 700;
}

.paragraph {
  margin: 0px !important;
  font-weight: 400;
  text-align: left;
  font-family: "Roboto Slab", serif;
  font-size: 24px;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  line-height: 36px;
}
@media (max-width: 600px) {
  .header {
    font-size: 35px;
  }
  .paragraph {
    font-size: 20px;
  }

  /* .contact-button-container{ display: none !important;} */

  .wwd,
  .new-text {
    margin-top: 50px;
  }
  .custom-section {
    padding: 50px 0;
  }
}
/* client section */
.client-section {
  text-align: center;
}

.client-heading {
  padding-top: 100px;
  margin: 0px !important;
  text-align: center;
  padding-bottom: 10px;

  letter-spacing: 0px;

  font-weight: 500;
  font-size: 48px;
}

.client-description {
  text-align: center;
  margin-bottom: 40px;
  font-family: "Roboto Slab", serif;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  font-size: 20px;
}
.container-fluid.marquee {
  position: relative;
  overflow: hidden;
  padding-bottom: 100px;
  width: calc(100% - 32px);
}

.marquee-content {
  margin: 0px 24px;
  gap: 20px;
  display: flex;
  white-space: nowrap;
  animation: marquee 20s linear infinite;
  box-sizing: content-box;
  padding: 0 16px;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.client-logo {
  height: 150px;
  width: 450px;
}

/* Pause animation on hover */
.container-fluid.marquee:hover .marquee-content {
  animation-play-state: paused;
}

@media (max-width: 600px) {
  .client-heading {
    font-size: 35px;
  }
  .client-description {
    font-size: 20px;
  }
  .marquee-content {
    width: calc(100% * 3);
  }
}

/* contact CSS */
.contact-section {
  background: #163365 0% 0% no-repeat padding-box;
  /* border: 1px solid #707070; */
  opacity: 1;
  padding: 50px 0;
}

.contact-header {
  text-align: left;
  font-family: "Roboto", serif;
  font-weight: 700;
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 36px;
  opacity: 1;
  margin-bottom: 10px;
}

.contact-description {
  text-align: left;
  font-family: "Roboto Slab", serif;
  font-weight: 400;
  margin: 14px 0px 30px 0px;
  font-size: 24px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.input-group {
  padding-bottom: 15px;
}
.inputStyle,
.inputStyle-textarea {
  height: 50px;
  border-radius: 0;
  /* border: 1px solid #ced4da; */
  border: none !important;
  outline: none !important;
  transition: none !important;
  width: 100%;
  box-shadow: none !important;
}

.input-icon {
  height: 50px;
  border-color: white;
  background-color: #ffffff;
  border-right: 0;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  padding: 15px 1px 15px 20px;
  margin: 0px !important;
  z-index: 1;
}
.input-icon1 {
  height: 100px;
  border-color: white;
  background-color: #ffffff;
  border-right: 0;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  padding: 19px 0px 61px 21px;
  margin: 0px !important;
  z-index: 1;
}

.inputStyle::placeholder {
  font-family: "Roboto Slab", serif;
  font-size: 14px;
  letter-spacing: 0px;
  color: #333;
  opacity: 1;
}

/* .image {
  margin-bottom: 50px;
} */
.inputStyle1::placeholder {
  font-family: "Roboto Slab", serif;
  font-size: 14px;
  letter-spacing: 0px;
  color: #333;
  opacity: 1;
}
.inputStyle {
  font-family: "Roboto Slab", serif;
  font-size: 14px;
  letter-spacing: 0px;
  color: #333;
  opacity: 1;
  height: 50px !important;
  border: none;
}
.abc {
  font-family: "Roboto Slab", serif;
  font-size: 14px;
  letter-spacing: 0px;
  color: #333;
  opacity: 1;
  height: 50px !important;
}
.inputStyle1 {
  font-size: 14px;
  scrollbar-width: none;
  padding: 17px 0px 64px 14px;
  height: 100px !important;
  border-radius: 0;
  border: none;
  width: 100%;
  border: none !important;
  outline: none !important;
  transition: none !important;
  width: 100%;
  box-shadow: none !important;
}
/* .select-style {
  padding-right: 40px;
  font-family: "Roboto Slab", serif;
  font-size: 14px;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  height: 50px;
  position: relative;
} */
.select-style {
  appearance: none; /* Remove the default arrow */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
  background-image: url("./Images/downarrow.png"); /* Custom arrow icon */
  background-repeat: no-repeat;
  background-position: right 10px center; /* Position the arrow */
  color: #000;
  background-size: 24px 24px; /* Adjust the size of the arrow */
  padding-right: 30px; /* Add some space for the arrow */
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 40px; /* Adjust as needed */
  outline: none !important;
}

.input-icon svg {
  height: 15px !important;
  width: 15px !important;
}
.select-style option {
  font-family: "Roboto Slab", serif;
  font-size: 14px;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
}
.button3 {
  outline: none !important;
  font-size: 15px;
  padding: 10px 20px;
  background-color: #f26836;
  border: none;
  color: #fff;
  border-radius: 0;
}

.button3:hover {
  background-color: #0a8fce;
}

.img-fluid {
  /* min-height: 251px; */
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .contact-description {
    font-size: 16px;
    text-align: center;
  }
}
@media (min-width: 1800px) {
  .inputStyle {
    padding: 17px 0px 14px 14px;
  }

  .button3 {
    width: 124px;
    height: 61px;
    font-size: 20px;
  }
}
.footer {
  background-color: #f9f9f9;
  padding-top: 100px;
}
@media (max-width: 600px) {
  /* .footer{
    padding-top: 530px !important;
  } */
  .inputStyle {
    padding: 9px;
  }
  .footerrow {
    gap: 0px !important;
    padding-top: 20px;
  }
  .list-unstyled {
    padding-top: 7px !important;
  }
  .footer-section1 h4 {
    padding-top: 20px;
  }
  .footer p a::after {
    content: "";
    position: absolute;
    left: 7px;
    bottom: 0px;
    width: auto !important;
    height: 1px;
    background-color: #333;
    transition: width 0.3s ease;
  }
}

.footer-logo {
  margin: 0px !important;
  padding-bottom: 20px;
  max-width: 100%;
}
.footer-section {
  padding: 0px !important;
  /* padding: 20px; */
  background-color: #f8f9fa;
  text-align: left;
}

.footer-section p {
  margin: 0px !important;
  font-weight: 400;
  padding-bottom: 30px;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  line-height: 19px;
}
.address {
  padding-bottom: 18px;
  line-height: 19px;
  margin: 0px !important;
  font-weight: 400 !important;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
}
.footer-section1 h5 {
  font-weight: 400;
  margin: 0px !important;
  text-align: left;
  font-family: "Roboto Slab", serif;
  font-size: 20px;
  letter-spacing: 0px;
  color: #163365;
  opacity: 1;
  padding-top: 30px;
}
.footer-section1 h4 {
  font-weight: 400;
  text-align: left;
  margin: 0px !important;
  font-family: "Roboto Slab", serif;
  font-size: 20px;
  letter-spacing: 0px;
  color: #163365;
  opacity: 1;
}

.contactno {
  margin: 0px !important;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #163365;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
}
.hr a,
.carrier a,
.business a {
  color: black;
  text-decoration: none; /* Remove default underline */
}
.hr a:hover,
.carrier a:hover,
.business a:hover {
  color: black !important;
  text-decoration: none !important; /* Remove default underline */
}
.hr a span:hover,
.carrier a span:hover,
.business a span:hover {
  color: #f26836 !important;
  text-decoration: underline !important;
}

.footer-section1 ul {
  padding-left: 0;
}

.list-unstyled li {
  /* padding-bottom: 10px; */
  text-align: left;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
}

.footer-section1 ul li a {
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  letter-spacing: 0px;
  color: #333;
  text-decoration: none;
  font-weight: 400;
}

.footer-section1 ul li a:hover {
  text-decoration: underline;
  color: #f26836;
}
.list-unstyled {
  line-height: 24px;
  padding-top: 15px;
  margin: 0px !important;
}

.social-icons {
  padding-bottom: 50px;
  padding-top: 17px;
  display: flex;
  gap: 15px;
}

.social-icons a {
  font-size: 24px;
  /* margin-right: 10px; */
  text-decoration: none;
}
.social-icons a:last-child {
  margin-right: 0;
}

.footer p a {
  position: relative;
  color: #333; /* Optional: set the link color */
}

.footer p a::after {
  content: "";
  position: absolute;
  left: 7px;
  bottom: 0px;
  width: 171px;
  height: 1px;
  background-color: #626262;
  transition: width 0.3s ease;
}

.scroll-top-btn {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none !important;
}

/* Uncomment and adjust if needed */
/* .scroll-top-btn:hover {
  background-color: #0056b3;
  border-color: #0056b3;
} */

.scroll-top-btn svg {
  height: 14px;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.footer-bottom p {
  font-family: "Roboto", sans-serif;
  margin: 0;
  font-size: 14px;
  color: #333333;
}

/* Media queries for responsive design */
@media (max-width: 767px) {
  .footer-section .col-md-3,
  .footer-section .col-md-2,
  .footer-section .col-md-4 {
    margin-bottom: 20px;
  }

  .social-icons {
    gap: 10px;
    align-items: center;
  }
}

.footer-bottom .text-center {
  padding: 30px 0px 34px 0px;
  flex: 1;
  text-align: center;
}

.footer-bottom .text-right {
  flex: 0;
  text-align: right;
  padding: 0px !important;
}
.footer p a {
  /* color: #f26836; */
  /* text-decoration: underline; */
  position: relative;
  text-decoration: none !important;
}

/* .footer p a:hover {
  text-decoration: underline;
} */
.scroll-top-btn {
  background-color: #f26836;
  border-color: white;
}
.social-icons img {
  height: 30px;
  width: 30px; /* Make the width equal to the height for a perfect circle */
  border-radius: 50%; /* This makes the background circular */
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
  /* Optional: adjust padding to control icon size inside the circle */
}
.social-icons img:hover {
  box-shadow: 0 0 10px #02a3ed; /* Adjust the shadow color and size as needed */
  transform: scale(1.1);
}
.social-icon:hover {
  background-color: #c9eaf9 0% 0% no-repeat padding-box !important;
}

/* React CSS */

.background-section {
  background-image: url("./Images/All2.jpg");
  background-size: cover;
  background-position: center;
  height: 800px;
  position: relative;
  display: flex;
  align-items: center;
}

.content {
  padding: 0px 300px 0px 0px;
  text-shadow: 0px 3px 6px #000;
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: rgb(0, 0, 0);
  text-align: right;
}

.react-text,
.development-text {
  color: white;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 84px;
  letter-spacing: 0px;
  margin: 0;
}
.react-text {
  font-weight: 500;
}
.development-text {
  font-weight: 300;
}

/* Info Section */
.Bestowal1 {
  background-image: url("./Images/Rely1.jpg");
}
.info-section {
  padding: 100px 0px;
}

.info-header {
  padding-bottom: 50px;
  text-align: center;
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  font-size: 72px;
  letter-spacing: 0px;
  color: #163365;
  opacity: 1;
  margin-bottom: 50px;
}

.info-content {
  display: flex;
  flex-wrap: wrap;
}

.image-column {
  position: relative;
}

.image-wrapper {
  position: relative;
  width: 100%;
}

.image-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 50px;
}

.first-image {
  border-radius: 50px;
  height: auto;
  position: relative;
  z-index: 2;
}

.second-image {
  border-radius: 50px;
  position: absolute;
  bottom: 20%;
  right: -20%;
  width: 50%;
  height: auto;
  z-index: 2;
}

.text-column {
  padding-left: 92px;
  display: flex;
}
@media (max-width: 600px) {
  .text-column {
    padding-left: 0px !important;
  }
}
.text-column p {
  line-height: 32px;
  font-weight: 400;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
}

/* Get In Touch Button */
.get-in-touch-btn {
  background: linear-gradient(to right, #ff7e5f, #feb47b);
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
}
/* Media Queries */
@media (max-width: 600px) {
  .background-section {
    height: auto;
    padding: 115px 0;
    display: block;
  }

  .content {
    position: relative;
    bottom: auto;
    right: auto;
    text-align: center;
    margin-bottom: 20px;
  }

  .react-text,
  .development-text {
    font-size: 48px;
    line-height: 56px;
    text-align: center;
  }

  .info-header {
    font-size: 35px;
  }

  .info-content {
    display: block;
  }

  .image-column {
    margin-bottom: 20px;
  }

  .second-image {
    /* bottom: -7%;
    right: 25%; */
    position: relative;
    bottom: auto;
    right: auto;
    width: 80%;
  }
  .text-column p {
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 16px;
  }
}
/* .opting {
    background-image: url("./Images/Opting1.jpg"); 
  } */

/*Neext Para*/
.custom-bg-container {
  background-image: url("./Images/Development.jpg");
}
.custom-content-container {
  padding: 106px 0px;
  margin: 0 auto;
}
.custom-title-heading {
  line-height: 95px;
}
.custom-title-heading h2 {
  margin: 0px !important;
  text-align: center;
  font-family: "Roboto Slab", serif;
  letter-spacing: 0px;
  color: #163365;
  font-weight: 400;
  font-size: 36px;
}
.reactrow {
  margin-top: 50px;
}

.custom-carousel-container {
  position: relative;
  width: 100%; /* Adjust as needed */
}

.custom-prev,
.custom-next {
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  font-size: 36px;
  color: #eeee;
  text-shadow: 0px 3px 6px #000000;
  cursor: pointer;
  z-index: 10; /* Ensure icons are above other elements */
}

.custom-prev {
  left: 50px; /* Adjust position from the left */
}

.custom-next {
  right: 50px; /* Adjust position from the right */
}

.custom-margin {
  margin-bottom: 24px;
}
.custom-title-heading h1 {
  margin: 0px !important;
  padding-bottom: 50px;
  text-align: center;
  color: #163365;
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  font-size: 72px;
}

.custom-paragraph {
  text-align: center;
  font: normal normal normal 20px/25px Roboto Slab;
  letter-spacing: 0px;
  color: #163365;
  opacity: 1;
  margin: 0 auto;
  max-width: 800px;
  padding: 0 20px;
}

.custom-card-wrapper {
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  border: none;
  border: 1px solid #73839f;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.custom-image-holder {
  padding: 10px 10px 0px;
  display: flex;
  justify-content: center;
}

.custom-img-top {
  max-width: 100%;
  height: auto;
}
.custom-title {
  margin: 0px !important;
  font-weight: 400;
  padding-top: 30px;
  text-align: center;
  font-family: "Roboto Slab", serif;
  font-size: 30px;
  letter-spacing: 0px;
  color: #163365;
  opacity: 1;
}

.custom-text {
  padding: 20px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #333333;
  margin: 0;
  height: auto !important;
  flex-grow: 1;
}
@media (max-width: 600px) {
  .custom-title-heading h2 {
    font-size: 30px;
  }
  .custom-title-heading h1 {
    font-size: 35px;
  }
  .custom-title {
    font-size: 25px;
  }
}
/* 2nd card css */

.custom-main-wrapper {
  padding: 100px 0px 0px;
}

/* Header styling */
.custom-main-heading h1 {
  font-size: 72px !important;
  font-weight: 700;
  text-align: center;
  font-family: "Roboto Slab", serif;
  letter-spacing: 0px;
  color: #163365;
  padding-bottom: 20px;
  margin: 0px !important;
  line-height: 95px;
}
@media (max-width: 600px) {
  .custom-main-heading h1 {
    font-size: 35px !important;
    line-height: 45px;
  }
  .customs-paragraph {
    font-size: 20px;
  }
}
/* Paragraph styling */
.customs-paragraph {
  line-height: 32px;
  /* margin-bottom: 60px; */
  letter-spacing: 1;
  opacity: 1;
  color: #163365;
  font-family: "Roboto Slab", serif;
  font-size: 24px;
  text-align: center;
  font-weight: 400;
}

/* Card styling */
/* .custom-main-wrapper {
  background-image: url();
} */
.custom-card {
  transition: transform 0.2s;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #73839f;
  display: flex;
  flex-direction: column;
  height: 100%;
}


.custom-card-image-wrapper {
  padding: 10px 10px 0px;
  display: flex;
  justify-content: center;
}


.custom-main-headings h1 {
  line-height: 95px;
  font-size: 72px !important;
  font-weight: 700;
  text-align: center;
  font-family: "Roboto Slab", serif;
  letter-spacing: 0px;
  color: #163365;
  padding-bottom: 50px;
  margin: 0px !important;
}
.custom-card-image {
  max-width: 100%;
  height: auto;
}
.custommargin1 {
  margin-bottom: 25px;
}
.custom-card-title {
  line-height: 39px;
  padding-top: 28px;
  margin: 0px !important;
  text-align: center;
  font-family: "Roboto Slab", serif;
  letter-spacing: 0px;
  color: #163365;
  font-size: 30px;
  opacity: 1;
  font-weight: 400;
}

.custom-card-text {
  line-height: 28px;
  padding: 20px;
  font-size: 18px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0px;
  color: #333333;
  margin: 0;
  height: auto !important;
  flex-grow: 1;
}
.react1 {
  background-repeat: no-repeat;
  background-color: #fff;
  background-image: url("./Images/Reacts1.jpg");
}
.benifits {
  background-color: #fff;
  padding: 100px 0px;
  margin: auto;
}
/* css of Benifits of React JS */
.benefits-heading {
  line-height: 79px;
  margin: 0px !important;
  padding-bottom: 30px;
  text-align: left;
  font-family: "Roboto Slab", serif;
  letter-spacing: 0px;
  color: #163365;
  opacity: 1;
  font-size: 60px;
  font-weight: 700;
}

.benefits-image {
  margin-left: 0;
}

.benefits-list {
  list-style-type: none;
  padding: 0;
}

.list-heading {
  font-weight: 500;
  margin: 0px !important;
  padding-bottom: 10px;
  text-align: left;
  font-family: "Roboto Slab", sans-serif;
  font-size: 30px;
  letter-spacing: 0px;
  color: #163365;
  opacity: 1;
  line-height: 39px;
}

.list-text {
  line-height: 28px;
  font-weight: 400;
  margin: 0px !important;
  padding-bottom: 30px;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
}
.rely {
  background-image: url("Images/Relyimg.jpg");
  height: 1514px;
}
@media (max-width: 600px) {
  .rely {
    height: auto !important;
  }
}

.rely1 {
  background-color: #fff;
}
.get-in-touch-btn {
  line-height: 26px;
  outline: none !important;
  height: 61px;
  background: #f26836 0% 0% no-repeat padding-box;
  font-weight: 500;
  text-align: left;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  border: none;
  border-radius: 0px;
  padding: 18px 30px;
}
.get-in-touch-btn:hover {
  background-color: #0a8fce;
  outline: none;
}
.background-container {
  padding-bottom: 160px;
  padding-top: 100px;
  text-align: center;
}

.background-container h1 {
  margin: 0px !important;
  padding-bottom: 50px;
  font-family: "Roboto Slab", serif;
  font-size: 72px;
  letter-spacing: 0px;
  color: #163365;
  opacity: 1;
}
@media (max-width: 600px) {
  .background-container h1 {
    font-size: 35px;
  }
}
.processContainer {
  background-repeat: no-repeat;
  background-image: url("./Images/Our_Process.png");
  /* background-size: cover; */
  background-position: center;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 0px;
  height: 630px;
}

.process-card {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 396px;
}

.process-card .card-title {
  font-family: "Roboto Slab", serif;
  font-size: 24px;
  color: #163365;
  margin-bottom: 15px;
}

.process-card .card-text {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #333;
}
.process-card1,
.process-card2,
.process-card3,
.process-card4 {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 396px;
}
@media (max-width: 600px) {
  .benefits-heading {
    font-size: 35px;
    text-align: center;
    line-height: 45px;
  }
  .list-heading {
    font-size: 25px;
  }
  .custom-main-headings h1 {
    font-size: 35px !important;
    line-height: 50px;
  }
}
/* body {
  background-color: #f8f9fa;
} */

.step-circle {
  /* margin-left: -105px;
    margin-top: 604px;
  position: relative;
  max-width: 250px; */
  width: 300px;
  margin-left: -105px;
  position: absolute;
  max-width: 250px;
}
.step-circle1 {
  margin-right: 54px;
  margin-bottom: 250px;
}
.step-text {
  /* position: absolute;
  top: -79px;
  width: 100%;
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
  color: #0056b3; */
  position: absolute;
  top: 121px;
  width: 300px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #30e2a1;
  margin-left: -143px;
  font-family: "Roboto Slab", serif;
  font-weight: 700;
}
/*  */

.step-text::after {
  content: "";
  display: block;
  width: 50px;
  height: 3px;
  background-color: #30e2a1;
  position: absolute;
  left: 158px;
  bottom: -3px;
  transform: translateX(-50%);
}

.step-text1 {
  /* margin-left: 15px;
  position: absolute;
  top: -177px;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #0056b3; */
  margin-left: -117px;
  position: absolute;
  top: -226px;
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #436efb;
  font-family: "Roboto Slab", serif;
  font-weight: 700;
}
.step-text1::after {
  content: "";
  display: block;
  width: 50px;
  height: 3px;
  background-color: #436efb;
  position: absolute;
  left: 123px;
  bottom: -3px;
  transform: translateX(-50%);
}
/* .step-circle2{
  margin-left: -70px;
  margin-top: 757px;
} */
.step-text3 {
  width: 305px;
  position: absolute;
  /* width: 292px; */
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #ffae55;
  /* top: 399px; */
  top: 121px;
  left: -46px;
  font-family: "Roboto Slab", serif;
  font-weight: 700;
}
.step-text3::after {
  content: "";
  display: block;
  width: 50px;
  height: 3px;
  background-color: #ffae55;
  position: absolute;
  left: 158px;
  bottom: -3px;
  transform: translateX(-50%);
}
.step-circle3 {
  margin-left: 56px;
  margin-bottom: 250px;
}
.icon-container {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: #e9ecef;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  color: #17a2b8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.step-description {
  left: -15px;
  width: 300px;
  position: absolute;
  top: 170px !important;
  font-size: 18px;
  color: #333;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.line {
  display: inline-block;
  width: 100%; /* Ensure line is aligned with the text width */
  height: 4px; /* Adjust height as needed */
  background-color: green; /* Line color */
  margin: 0; /* Remove margin if you want the line to be flush with text */
}

.step-description2 {
  position: absolute;
  top: -176px;
  width: 300px;
  /* margin-top: 109px; */
  margin-left: -85px;
  /* margin-right: -52px; */
  text-align: center;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.step-description3 {
  left: -36px;
  width: 300px;
  top: 170px;
  position: absolute;
  /* margin-top: -299px; */
  font-size: 18px;
  color: #333;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.step-description4 {
  left: 5px;
  width: 300px;
  top: -176px;
  position: absolute;
  /* margin-top: -214px; */
  /* margin-left: -50px; */
  /* margin-right: -113px; */
  text-align: center;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.step-text4 {
  top: -226px;
  position: absolute;
  /* margin-left: 10px; */
  /* top: -270px; */
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #7d4cd5;
  left: 30px;
  font-family: "Roboto Slab", serif;
  font-weight: 700;
}
.step-text4::after {
  content: "";
  display: block;
  width: 50px;
  height: 3px;
  background-color: #7d4cd5;
  position: absolute;
  left: 104px !important;
  bottom: -3px;
  transform: translateX(-50%);
}
.step-text5 {
  left: 44px;
  width: 300px;
  /* right: -82px; */
  /* top: 397px; */
  position: absolute;
  /* width: 100%; */
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #fc6799;
  top: 457px;
  font-family: "Roboto Slab", serif;
  font-weight: 700;
}
.step-text5::after {
  content: "";
  display: block;
  width: 50px;
  height: 3px;
  background-color: #fc6799;
  position: absolute;
  left: 158px;
  bottom: -3px;
  transform: translateX(-50%);
}
.step-circle4 {
  margin-top: 674px;
}
.step-description5 {
  left: 52px;
  top: 503px;
  width: 300px;
  position: absolute;
  /* margin-top: -230px; */
  font-size: 18px;
  color: #333;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
/* .my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
} */
@media (max-width: 600px) {
  /* Hide background image on mobile view */
  .processContainer {
    background-image: none;
  }

  /* Stack the process steps vertically */
  .row {
    /* flex-direction: column; Stack items vertically */
    align-items: center; /* Center-align items */
    text-align: center; /* Center-align text */
  }

  /* Adjust step circles */
  .step-circle,
  .step-circle1,
  .step-circle2,
  .step-circle3,
  .step-circle4 {
    position: relative; /* Relative positioning for stacking */
    width: auto; /* Auto width to fit content */
    margin: 10px 0; /* Vertical margin between steps */
    padding: 20px; /* Padding for spacing */
  }

  /* Adjust text within circles */
  .step-text {
    font-size: 24px; /* Adjust font size for mobile */
    color: #30e2a1; /* Ensure text is readable */
    margin: 10px 0; /* Margin for spacing */
    display: block; /* Block display to ensure vertical alignment */
    top: -64px !important;
    font-family: "Roboto Slab", serif;
    font-weight: 700;
  }
  .step-text1 {
    left: -24px;
    top: 85px;
    font-size: 24px;
    color: #436efb;
    margin: 10px 0;
    display: block;
    font-family: "Roboto Slab", serif;
    font-weight: 700;
  }
  .step-text1::after {
    content: "";
    display: block;
    width: 50px;
    height: 3px;
    background-color: #436efb;
    position: absolute;
    left: 170px;
    bottom: -3px;
    transform: translateX(-50%);
  }
  .step-text3 {
    left: -3px;
    top: 247px;
    font-size: 24px;
    color: #ffae55;
    margin: 10px 0;
    display: block;
    font-family: "Roboto Slab", serif;
    font-weight: 700;
  }
  .step-text4 {
    left: -19px;
    top: 405px;
    font-size: 24px;
    color: #7d4cd5;
    margin: 10px 0;
    display: block;
    font-family: "Roboto Slab", serif;
    font-weight: 700;
  }
  .step-text4::after {
    content: "";
    display: block;
    width: 50px;
    height: 3px;
    background-color: #7d4cd5;
    position: absolute;
    left: 139px !important;
    bottom: -3px;
    transform: translateX(-50%);
  }
  .step-text5 {
    left: -14px;
    top: 606px;
    font-size: 24px;
    color: #fc6799;
    margin: 10px 0;
    display: block;
    font-family: "Roboto Slab", serif;
    font-weight: 700;
  }
  /* Adjust descriptions */
  .step-description {
    text-align: center;
    left: 0px !important;
    top: 0px !important;
    font-size: 16px;
    color: #666;
    margin: 5px 0 20px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
  }
  .step-description2 {
    text-align: center;
    left: 0px !important;
    top: 145px !important;
    font-size: 16px;
    color: #666;
    margin: 5px 0 20px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
  }
  .step-description3 {
    text-align: center;
    left: 0px !important;
    top: 313px !important;
    font-size: 16px;
    color: #666;
    margin: 5px 0 20px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
  }
  .step-description4 {
    text-align: center;
    left: 0px !important;
    top: 482px !important;
    font-size: 16px;
    color: #666;
    margin: 5px 0 20px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
  }
  .step-description5 {
    text-align: center;
    left: 0px !important;
    top: 670px !important;
    font-size: 16px;
    color: #666;
    margin: 5px 0 20px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
  }
}

/* SAP CSS */

.sap {
  /* background-image: url('Images/SAP.jpg'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  max-width: 100%;
  height: 800px;
  padding: 0px !important;
}
.sap img {
  height: 800px;
  width: 100%;
}
/* .bgsapibp{
  background-image: url('Images/bg-why-SAP-IBP.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  max-width: 100%;
  height: 800px;
}
.sapibp{
  display: flex;
  justify-content: center;
  align-items: center;
}
.sapibp img{
  margin-top: 100px;
  height: 383px;
} */
.bgsapibp {
  background-image: url("Images/bg-why-SAP-IBP.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  max-width: 100%;
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sapibp {
  position: relative;
  width: 100%;
  height: 100%;
}
/* .roadmap_image{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%; 
} */
.roadmap-center {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.roadmap_image {
  height: 480px;
}
.roadmap-center h2 {
  position: absolute;
  top: 70px;
  font-size: 30px;
  /* font-weight: bold; */
  color: #000;
  left: 284px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.roadmap-center h2 span {
  font-weight: 700;
}
.roadmap-item {
  position: absolute;
  width: 300px; /* Adjust width as needed */
  color: #000; /* Change text color as needed */
  font-family: "Roboto", sans-serif;
}

.item1 {
  width: 273px;
  text-align: right;
  top: 25%;
  left: 2%;
}

.item2 {
  top: 24%;
  right: 0%;
}

.item3 {
  width: 350px;
  text-align: right;
  position: absolute;
  top: 55%;
  left: 6%;
}

.item4 {
  top: 54%;
  right: 9%;
}

.item5 {
  top: 82%;
  text-align: center;
  top: 67%;
  left: 40%;
  position: absolute;
}

/* Style the heading and paragraph */
.item1 h3 {
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #fe9700;
}
.item2 h3 {
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #673bb6;
}
.item3 h3 {
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #25c5d9;
}
.item4 h3 {
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #eb407a;
}
.item5 h3 {
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #42a4f5;
}
.item1 p,
.item2 p,
.item3 p,
.item4 p,
.item5 p {
  font-family: "Roboto", sans-serif;
  font-size: 16px !important;
}
.roadmap-item p {
  font-size: 16px;
  line-height: 1.5;
}
/* For mobile devices */
@media only screen and (max-width: 767px) {
  .roadmap_image {
    display: none; /* Hide the image on smaller screens */
  }

  .roadmap-item {
    width: 100%; /* Make each item take up full width */
    text-align: center; /* Center the text */
    position: relative; /* Reset the position */
    margin-bottom: 20px; /* Add some space between items */
  }

  .item1,
  .item2,
  .item3,
  .item4,
  .item5 {
    left: 0; /* Reset any positioning */
    right: 0; /* Reset any positioning */
    top: 220px; /* Reset any positioning */
  }

  .roadmap-center h2 {
    position: relative; /* Adjust the heading */
    top: 0; /* Reset any positioning */
    left: 0; /* Reset any positioning */
    transform: none; /* Reset the transform */
    font-size: 40px; /* Adjust font size if needed */
  }
  .roadmap-center {
    top: 100px;
    left: 50%;
    text-align: center;
  }
}

/* For tablets */
/* For tablets (iPad) */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .roadmap_image {
    display: none;
  }

  .roadmap-center h2 {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    font-size: 35px;
    text-align: center;
    margin-bottom: 20px;
  }

  .roadmap-center {
    top: 0;
    position: relative;
    margin-top: 20px;
    text-align: center;
    left: 0;
    transform: none;
  }

  .roadmap-item {
    width: 100%;
    text-align: center;
    position: relative;
    margin-bottom: 20px;
  }

  .item1,
  .item2,
  .item3,
  .item4,
  .item5 {
    left: 0;
    right: 0;
    top: 0;
  }
  .item1 h3,
  .item2 h3,
  .item3 h3,
  .item4 h3,
  .item5 h3 {
    font-size: 25px;
  }
  .item1 p,
  .item2 p,
  .item3 p,
  .item4 p,
  .item5 p {
    font-size: 18px !important;
  }
  .sap img {
    height: auto;
    width: 100%;
  }
  .sap {
    height: auto;
  }
  .howsap {
    background-image: none !important;
    height: auto !important; /* Adjust height for better content fitting */
    background-size: contain; /* Adjust background image size */
    background-position: top center; /* Adjust background image position */
  }

  .howsap_section1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; /* Center text */
  }

  .howsap_section1 h2 {
    padding: 0 !important;
    text-align: center !important;
    font-size: 36px !important; /* Adjust font size */
  }

  .howsap_section1 p {
    padding: 20px 0px 0px 0px !important;
    font-size: 16px !important; /* Adjust font size */
  }

  .howsap_section1 img {
    display: none; /* Hide image on iPad */
  }

  .row {
    display: flex;
    flex-direction: column; /* Stack columns vertically */
  }

  .col-md-4,
  .col-md-8 {
    width: 100%; /* Full width for both columns */
    margin: 0; /* Remove margin */
  }

  .col-md-8 {
    margin-top: 0 !important; /* Remove margin-top for better spacing */
  }
  .SAP_Alternatives {
    margin-top: 100px;
  }
  .SAPIBP_Alternatives {
    margin: 0 16px; /* Adjust margin */
  }

  .Alternatives h1 {
    font-size: 36px !important; /* Adjust font size for heading */
    padding-top: 60px; /* Adjust padding */
  }

  .Alternatives p {
    font-size: 20px !important; /* Adjust font size for paragraph */
    padding-top: 10px; /* Adjust padding */
    padding-bottom: 30px; /* Adjust padding */
  }

  .Alternatives .row {
    display: flex;
    flex-direction: column; /* Stack columns vertically */
  }

  .Alternatives .col-md-4 {
    width: 100%; /* Full width for columns */
    margin-bottom: 20px; /* Space between items */
  }

  .sapcards {
    margin-bottom: 20px; /* Space between cards */
  }

  .sapcards h5 {
    font-size: 24px; /* Adjust font size for card titles */
  }

  .sapcards p {
    font-size: 16px; /* Adjust font size for card text */
  }
  .SAP_Principle {
    height: auto !important;
  }
  .Started img {
    height: auto !important;
  }
  .Started_SAPIBP {
    height: auto; /* Adjust height for smaller screens */
  }

  .Started h1 {
    font-size: 48px; /* Smaller font size for headings */
    padding-top: 50px;
    padding-bottom: 30px;
  }

  .text-container {
    position: static; /* Stack steps vertically */
    text-align: center;
  }

  .step1,
  .step2,
  .step3,
  .step4 {
    position: static;
    width: 100%;
    margin: 20px auto; /* Center steps */
  }

  .step1 {
    top: 115px !important;
    left: 235px !important;
  }

  .step2 {
    top: 333px !important;
    left: 435px !important;
  }

  .step3 {
    left: 225px !important;
    top: 546px !important;
  }

  .step4 {
    left: 10px !important;
    top: 333px !important;
  }
  .step2 p {
    font-size: 10px !important;
    padding: 0px 10px !important;
    width: 267px !important;
  }
  .step4 p {
    font-size: 10px !important;
    text-align: center;
    width: 205px;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    font-size: 16px;
    padding: 0px 0px 0px 73px;
  }
  .step4 h1 {
    width: 281px !important;
  }
  .description {
    font-size: 12px; /* Smaller font size for descriptions */
  }

  .Started img {
    width: 100%; /* Make image responsive */
    height: auto;
    margin-top: 20px;
  }
  .step1 h1,
  .step2 h1,
  .step3 h1,
  .step4 h1 {
    font-size: 20px !important;
  }
  .step1 p,
  .step3 p {
    font-size: 10px !important;
    padding: 0px 10px !important;
    width: 267px !important;
  }
  .SAPIBP_principle h1 {
    font-size: 36px !important;
  }
  .Started h1 {
    font-size: 35px o !important;
  }
}
@media (max-width: 768px) {
  .Started_SAPIBP .text-container {
    display: block; /* Stack elements one below the other */
  }

  .Started_SAPIBP img {
    display: none; /* Hide the image in mobile view */
  }

  .Started_SAPIBP h1,
  .Started_SAPIBP .description {
    text-align: center; /* Optionally center-align text for mobile */
  }

  .Started_SAPIBP .step1,
  .Started_SAPIBP .step2,
  .Started_SAPIBP .step3,
  .Started_SAPIBP .step4 {
    margin-bottom: 20px;
    left: 0px !important; /* Add space between steps */
  }
  .Started_SAPIBP .step4 {
    top: 0px !important;
  }
  .Started_SAPIBP .step2 {
    top: 395px !important;
  }
}
/* For large screens (desktop) */
@media only screen and (min-width: 1025px) {
  .roadmap_image {
    display: block;
  }

  .roadmap-item {
    position: absolute;
  }

  .roadmap-center h2 {
    font-size: 18px;
  }
}

.howsap {
  margin-top: 112px;
  background-image: url("./Images/new-bg.png");
  margin-bottom: 100px;
  background-position: center;
  background-repeat: no-repeat;
  max-width: 100%;
}
.howsap_section1 h2 {
  padding: 0px 0px 44px 205px;
  text-align: left;
  color: #163365;
  font-size: 48px;
  font-family: "Roboto Slab", serif;
}
.howsap_section1 p {
  padding: 0px 0px 0px 212px;
  font-size: 18px;
  color: #333;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.howsap_section1 img {
  height: auto;
  /* margin-left: 20px;
  margin-top: 85px; */
}
.SAPIBP_Alternatives {
  margin: 0px 24px 0px 24px;
  background-color: #b7c7ff;
  border-radius: 50px 50px 50px 50px;
}
.Alternatives h1 {
  font-family: "Roboto Slab", serif;
  font-size: 72px;
  font-weight: 700;
  text-align: center;
  padding-top: 100px;
  margin: 0px !important;
  letter-spacing: NaNpx;
  color: #163365;
  opacity: 1;
}
.Alternatives p {
  font-family: "Roboto Slab", serif;
  font-size: 30px;
  margin: 0px !important;
  font-weight: 400;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 50px;
  color: #163365;
}
.Alternatives .row {
  margin-bottom: 100px;
  display: flex;
  flex-wrap: wrap;
}

.Alternatives .col-md-4 {
  margin: 0px !important;
  display: flex;
  align-items: stretch;
  padding-right: 24px;
}
.SAPIBP_principle .col-md-4 {
  margin: 0px !important;
  display: flex;
  align-items: stretch;
}
.sapcards {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  border: 1px solid #e0e0e0;
  /* border-radius: 8px; */
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1;
}

.card-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.sap-container {
  flex-shrink: 0;
}

.sap-container img {
  padding: 10px 10px 30px 10px;
  width: 100%;
  height: auto;
  /* border-radius: 5px; */
}

.sapcards h5 {
  padding: 0px 20px;
  text-align: center;
  font-family: "Roboto Slab", serif;
  letter-spacing: 0px;
  color: #163365;
  opacity: 1;
  font-size: 30px;
  margin-top: 0;
  margin: 0px !important;
  flex-shrink: 0;
}

.sapcards p {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  margin: 0 10px 20px 10px;
  padding: 20px;
  display: flex;
  align-items: flex-end;
}
.SAP_Principle {
  margin-top: 100px;
  background: #f2f5fc 0% 0% no-repeat padding-box;
  opacity: 1;
  background-image: url("./Images/Principal.png");
  /* height:1537px; */
}
.SAPIBP_principle h1 {
  font-family: "Roboto Slab", serif;
  font-size: 72px;
  font-weight: 700;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 50px;
  margin: 0px !important;
  letter-spacing: NaNpx;
  color: #163365;
  opacity: 1;
}
.about .col-md-6 {
  padding-right: 20px;
}
.principalcards {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  border: 1px solid #73839f;
  /* border-radius: 8px; */
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1;
}

.principalcards h5 {
  text-align: center;
  font-family: "Roboto Slab", serif;
  letter-spacing: 0px;
  color: #163365;
  opacity: 1;
  font-size: 30px;
  margin-top: 0;
  margin: 0px !important;
  flex-shrink: 0;
}

.principalcards p {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  /* margin: 0 10px 20px 10px; */
  padding: 20px;
  display: flex;
  align-items: flex-end;
  margin: 0px !important;
}
/* .Started_SAPIBP{
  margin-top: 100px;
  height: 1561px;
background: #F9F9F9;
opacity: 1;
background-image: url("Images/SAP_IBP.png");
}
.Started h1{
  font-family: "Roboto Slab", serif;
  font-size: 72px;
  font-weight: 700;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 50px;
  margin: 0px !important;
letter-spacing: NaNpx;
color: #163365;
opacity: 1;
} */
.Started_SAPIBP {
  align-items: center;
  display: flex;
  justify-content: center;
  background-image: url(https://www.bestowalsystems.com//static/media/SAP_IBP.170d14c….png) !important;
  background-color: #f9f9f9;
  margin-top: 100px;
  height: 1561px;
  background: #f9f9f9;
  opacity: 1;
  position: relative;
}

.Started h1 {
  font-family: "Roboto Slab", serif;
  font-size: 72px;
  font-weight: 700;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 50px;
  margin: 0px !important;
  letter-spacing: NaNpx;
  color: #163365;
  opacity: 1;
}

.text-container {
  position: relative;
}

.step1 {
  top: 190px;
  left: 500px;
  position: absolute;
  width: 300px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: #000;
  text-align: left;
}
.step2 {
  top: 540px;
  left: 890px;
  position: absolute;
  width: 300px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: #000;
  text-align: left;
}
.step3 {
  left: 500px;
  top: 869px;
  position: absolute;
  width: 300px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: #000;
  text-align: left;
}
.step4 {
  left: 103px;
  top: 540px;
  position: absolute;
  width: 300px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: #000;
  text-align: left;
}
.description {
  font-size: 14px;
  color: #555; /* Adjust text color */
  margin-top: 10px;
}
.step1 h1 {
  padding: 0px !important;
  text-align: center;
  letter-spacing: 0px;
  color: #00b87b;
  opacity: 1;
  font-size: 27px;
  font-family: "Roboto Slab", serif;
  font-weight: 700;
}
.step1 p {
  text-align: center;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  font-size: 16px;
}
.step2 h1 {
  padding: 0px !important;
  text-align: center;
  letter-spacing: 0px;
  color: #f09800;
  opacity: 1;
  font-size: 27px;
  font-family: "Roboto Slab", serif;
  font-weight: 700;
}
.step2 p {
  text-align: center;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  font-size: 16px;
}
.step3 h1 {
  padding: 0px !important;
  text-align: center;
  letter-spacing: 0px;
  color: #004763;
  opacity: 1;
  font-size: 27px;
  font-family: "Roboto Slab", serif;
  font-weight: 700;
}
.step3 p {
  text-align: center;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  font-size: 16px;
}
.step4 h1 {
  padding: 0px !important;
  text-align: center;
  letter-spacing: 0px;
  color: #ff465f;
  opacity: 1;
  font-size: 27px;
  font-family: "Roboto Slab", serif;
  font-weight: 700;
}
.step4 p {
  text-align: center;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  font-size: 16px;
}

.roadmap {
  background-image: url("./Images/bg-Roadmap.jpg");
  height: 1280px;
}
.saproadmap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.saproadmap h1 {
  font-family: "Roboto Slab", serif;
  font-size: 71px;
  font-weight: 700;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 20px;
  margin: 0px !important;
  letter-spacing: NaNpx;
  color: #163365;
  opacity: 1;
}
.saproadmap p {
  font-family: "Roboto Slab", serif;
  font-size: 30px;
  margin: 0px !important;
  font-weight: 400;
  text-align: center;
  padding-bottom: 50px;
  color: #163365;
}
.SAP_Cloud {
  align-items: center;
  height: 200px;
}
.clouds {
  margin-top: 58px;
  margin-bottom: 11px;
  display: flex;
  gap: 270px;
}
.Clouds {
  padding-top: 12px;
  width: 903px;
  gap: 184px;
  display: flex;
}
.clouds h2,
.Clouds h2 {
  margin: 0px !important;
  font-size: 36px;
}
.roadmapimg {
  height: 460px;
}
.Started img {
  height: 1155px;
  width: 100%;
}
/* General adjustments for iPad view */
@media (max-width: 1024px) {
  .roadmap {
    background-image: url("./Images/bg-Roadmap.jpg");
    height: auto; /* Let the content define the height */
    padding: 20px; /* Add some padding for better spacing */
  }

  .saproadmap h1 {
    font-size: 50px; /* Reduce the font size for smaller screens */
    padding-top: 50px; /* Adjust padding to fit better */
    padding-bottom: 10px;
  }

  .saproadmap p {
    font-size: 24px; /* Adjust the font size for readability */
    padding-bottom: 30px; /* Adjust spacing */
  }

  .SAP_Cloud {
    height: auto; /* Allow image to scale based on content */
    max-width: 100%; /* Ensure the image doesn't overflow */
  }

  .clouds {
    align-items: center;
    gap: 20px; /* Adjust the gap for vertical alignment */
    /* margin-top: 30px; */
  }

  .Clouds {
    flex-direction: column; /* Stack items vertically */
    align-items: center;
    gap: 20px; /* Adjust gap for better alignment */
    width: 100%; /* Ensure the section takes full width */
    padding-top: 30px; /* Adjust padding */
    margin-bottom: 0px !important;
  }

  .clouds h2,
  .Clouds h2 {
    font-size: 28px; /* Adjust font size */
    text-align: center; /* Center-align text */
  }

  .roadmapimg {
    height: auto; /* Make image responsive */
    max-width: 100%; /* Ensure image doesn't overflow */
    margin: 30px 0; /* Add some margin for spacing */
  }

  .Started img {
    height: auto; /* Allow image to scale naturally */
    max-width: 100%; /* Ensure image doesn't overflow */
  }
}

/* Further adjustments for smaller screens (portrait mode) */
@media (max-width: 768px) {
  .saproadmap h1 {
    font-size: 40px;
  }

  .saproadmap p {
    font-size: 20px;
  }

  .clouds,
  .Clouds {
    gap: 10px; /* Reduce gap for smaller screens */
  }

  .clouds h2,
  .Clouds h2 {
    font-size: 24px;
  }
}

@media (max-width: 600px) {
  .sap img {
    height: auto;
  }
  .sap {
    height: auto !important;
    background-image: none !important;
  }

  .bgsapibp {
    height: auto !important;
    background-image: none;
  }

  .sapibp img {
    margin-top: 50px; /* Adjust margin-top for mobile view */
    height: auto; /* Ensure the image scales correctly */
  }

  .howsap {
    height: auto; /* Adjust height for mobile view */
    background-image: none;
  }
  .howsap_section1 img {
    display: none;
  }
  .howsap_section1 h2 {
    padding: 0 10px; /* Adjust padding for mobile view */
    font-size: 24px; /* Reduce font size for mobile view */
    text-align: center; /* Center-align text on smaller screens */
  }

  .howsap_section1 p {
    padding: 0 10px; /* Adjust padding for mobile view */
    font-size: 16px; /* Reduce font size for mobile view */
    text-align: center; /* Center-align text on smaller screens */
  }

  .howsap_section1 img {
    margin-left: 0; /* Remove left margin on mobile view */
    margin-top: 20px; /* Adjust margin-top for mobile view */
  }
  .SAPIBP_Alternatives {
    margin: 100px 0px 0px 0px !important;
  }
  .Alternatives h1 {
    font-size: 55px;
  }
  .Alternatives p {
    font-size: 25px;
  }
  .Alternatives .col-md-4 {
    margin: 0px !important;
    display: block;
  }
  .sapcards h5 {
    font-size: 25px;
  }
  .sapcards p {
    font-size: 16px;
  }
  .SAPIBP_principle h1 {
    font-size: 35px;
  }
  .SAP_Principle {
    height: auto;
  }
  .principalcards h5 {
    font-size: 25px;
  }
  .principalcards p {
    font-size: 16px;
  }
  .carrierrow p {
    text-align: center !important;
  }
  .carrierrow h2 {
    padding-top: 30px !important;
    font-size: 35px !important;
    text-align: center !important;
  }
  .search-section {
    height: auto !important;
    border-radius: 0px !important;
  }
}
/* @media (min-width: 768px) {
  .navbar-collapse.collapse {
      display: none !important;}} */

/* Blogs CSS */
.blogdetails {
  margin-top: 100px;
}
.blogs {
  position: relative;
  height: 500px;
  /* background-image: url("./Images/blogs.jpg"); */
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blogs::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Ensures the overlay is below the text */
}
.blogcontainer {
  padding-bottom: 100px;
}
.blogcontainer a {
  text-decoration: none !important;
}
.blogcontainer .col-md-4 {
  padding-bottom: 50px;
}
.blogs h1 {
  margin: 0px !important;
  font-size: 72px !important;
  position: relative;
  color: white;
  z-index: 2; /* Ensures the text is above the overlay */
}

.header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.category-button {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
}

.category-button i {
  margin-left: 5px;
}

/* .category-button:hover {
  background-color: #f0f0f0;
} */

/* Dropdown container */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: 100%;
  border-radius: 5px;
}

.dropdown-content a {
  font-size: 14px;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.blogcontainer button {
  background: #163365;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
}
.cards-container {
  display: flex;
  flex-wrap: wrap; /* Allow cards to wrap on smaller screens */
  gap: 20px; /* Space between cards */
}

.card {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(33.333% - 20px); /* Three columns with gap adjustment */
  display: flex; /* Use Flexbox */
  flex-direction: column; /* Stack items vertically */
}

.card-img {
  position: relative;
}

.card-img img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.badgee {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ff5733;
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 8px;
  z-index: 1;
}
.badge {
  color: white;

  font-size: 12px;
  font-weight: bold;
  border-radius: 8px;
  z-index: 1;
}
.card-body {
  /* padding: 15px; */
  display: flex; /* Use Flexbox for card body */
  flex-direction: column; /* Stack elements vertically */
  flex-grow: 1; /* Allow it to expand and take available space */
}

.date {
  font-size: 12px;
  color: #999;
  margin: 0; /* Remove default margin */
}

.card-title {
  font-size: 24px;
  font-weight: bold;
  margin: 12px 0 10px; /* Consistent margins */
}

.description {
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
  flex-grow: 1; /* Allow description to take up space */
}
.card-link {
  text-decoration: none;
  color: inherit; /* Optional: to ensure the text color remains the same */
}

.card-link:hover {
  text-decoration: none; /* Optional: keep the same on hover */
}
.blogsinformation {
  padding: 50px 0px;
}
.blogsrow {
  padding-top: 50px;
}
.blogsrow .col-md-4 {
  padding-bottom: 50px;
}
.category-dropdown {
  color: #000;
  background-color: #d9d9d9;
  outline: none;
  font-size: 16px;
  padding: 3px;
  width: 207px;
  height: 48px;
  border: none;
  font-family: "Roboto", sans-serif;
}
.search-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.search-form {
  display: flex;
  align-items: center;
  gap: 50px;
  /* max-width: 600px; */
}

.search-bar {
  flex: 1;
  padding: 10px;
  border: 2px solid black;
  outline: none;
  font-size: 18px;
  width: 500px;
}
.search-form h2 {
  font-size: 40px;
}
.search-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
}

.search-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Career CSS */
.containerfluid {
  background-image: url("Images/career_page.jpg"); /* Replace with your image URL */
  background-size: cover;
  background-position: center;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}

.careerlanding h2 {
  color: white;
  font-size: 72px;
  font-family: "Roboto Slab", serif;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6); /* Adds a shadow for better readability */
  z-index: 1; /* Ensure the text is above the background */
}

/* Optional: If the background image is too strong, add a semi-transparent overlay */
.containerfluid::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  z-index: 0;
}

.careers {
  position: relative;
  height: 500px;
  background-image: url("./Images/blogs.jpg");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.careers::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Ensures the overlay is below the text */
}
.careers h1 {
  margin: 0px !important;
  font-size: 72px !important;
  position: relative;
  color: white;
  z-index: 2; /* Ensures the text is above the overlay */
}
.career {
  padding-top: 100px;
}
.careerrow {
  padding: 55px 65px;
}
.back-button {
  background: #163365;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
}
.careerrow p {
  color: #fff;
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.search-section {
  background-color: #163365; /* Dark blue background */
  border-radius: 8px;
  height: 156px;
  border-radius: 80px !important;
}
.form-control {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  height: 48px;
}
.ms-auto {
  display: contents;
  margin-left: auto !important;
}
.jobtablerow {
  display: flex;
}
.jobtablerow .col-md-2 {
  margin-top: 7%;
}
.filterbtn {
  margin-right: 15px;
  padding-right: 10px;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  height: 48px;
  font-size: 16px;
  background-color: #f26836;
  color: #fff;
  font-family: "Roboto", sans-serif;
}
.resetbtn {
  margin-right: 15px;
  padding-right: 10px;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  height: 48px;
  font-size: 16px;
  background-color: #fff;
  border: 1px solid #000;
  color: #000;
  font-family: "Roboto", sans-serif;
}
.form-controls {
  padding: 10px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  height: 40px;
}
.page-link {
  border: none !important;
}
.pagination > li > a,
.pagination > li > span {
  border: none !important;
}
.careerbutton {
  padding: 10px 25px;
  color: #fff;
  background-color: #f26836;
  border-radius: 6px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  height: 48px;
}
/* Alert Section */
/* Styling for the increment-decrement buttons */
/* Styling for the increment-decrement buttons */
/* Styling for the increment-decrement buttons */
.number-input {
  display: inline-flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}

.number-input input {
  width: 50px;
  text-align: center;
  border: none;
  outline: none;
  font-size: 1rem;
}

.number-input button {
  width: 30px;
  background-color: #007bff;
  color: white;
  border: none;
  font-size: 1.25rem;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.number-input button:hover {
  background-color: #0056b3;
}

.decrement {
  border-right: 1px solid #ccc;
}

.increment {
  border-left: 1px solid #ccc;
}

.pagination .page-link {
  height: 35px;
 
  font-size: 24px;
  width: 35px;
  padding: 2px;
}
.page-item:active
/* Ensure both sections are aligned properly */
.d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Prevent overflow if window is resized */
}

.pagination {
  margin-bottom: 0; /* Remove bottom margin for pagination */
}

.no-vertical-lines td,
.no-vertical-lines th {
  border-left: none;
  border-right: none;
}

.no-vertical-lines {
  border-collapse: collapse;
}
.bordered > thead > tr > th {
  border: none !important;
}

.alert-section .btn {
  height: 38px;
}

.alert-section .btn {
  height: 38px;
}

/* Table Styling */
.table {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
}

.table a {
  text-decoration: none;
  color: #007bff;
}
/* .table>tbody>tr>td{
  padding: 0px !important;
} */
.job-header td {
  padding: 16px 0px;
}
.table a:hover {
  text-decoration: underline;
}
/* .table>tbody>tr>td{
  padding: 16px 0px;
} */
.jobtable {
  padding-bottom: 100px;
}
.job-header th {
  color: #163365;
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  margin: 0px !important;
  padding-bottom: 30px !important;
}
.job-header td a {
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #163365;
}
.job-header td {
  padding: 16px 0px !important;
}
/* Pagination Styling */
.job-header td {
  font-size: 24px;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.careerobjective {
  padding-bottom: 100px;
}
.careerobjective h1 {
  font-size: 36px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #000;
  padding-bottom: 20px;
}
.careerobjective h3 {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  color: #000;
  padding-bottom: 5px;
}
.careerobjective p {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  color: #000;
  font-weight: 400;
  padding-top: 35px;
}
.applybutton {
  display: flex;
  margin-top: 30px;

  color: #fff;
}
/* Login Page CSS */
.login {
  margin-top: 100px;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.login-form {
  background-color: #fff;
  /* padding:200px 40px; */
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 590px;
  width: 100%;
  text-align: center;
}

.login-form h2 {
  color: #000;
  font-size: 36px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.login-form p {
  font-size: 24px;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.login-form p a {
  font-size: 18px;
  color: #0094db;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.login-form h3 {
  margin: 0px !important;
  color: #020019;
  font-size: 18px;
  padding: 40px 0px;

  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.login-form .input-group .input {
  width: 100%;
  padding: 12px 12px 12px 40px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}
.input-group {
  position: relative;
  margin: 0px !important;
  padding-bottom: 30px;
}

.input-group .material-symbols-outlined {
  position: absolute;
  left: 12px;
  top: 28%;
  transform: translateY(-50%);
  color: #aaa;
  font-size: 18px;
}
.forgot-password {
  padding-bottom: 25px;
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-decoration: none;
}
.register-link p {
  font-size: 18px;
  color: #333;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  padding-bottom: 15px;
}
.register-link h4 {
  font-size: 18px;
  color: #333;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.register-link h4 a {
  color: #0094db;
}
.input-group input {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #000;
}

/* .forgot-password {
  font-size: 12px;
  display: block;
  text-align: right;
  margin-top: 8px;
  color: #007bff;
} */

.forgot-password:hover {
  text-decoration: underline;
}

.btn-signin {
  width: 100%;
  background-color: #f26836;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 25px;
  margin-bottom: 50px;
}
.btn-submit {
  width: 100%;
  background-color: #f26836;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 20px;
}
.login-form h4 {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin-bottom: 40px;
}
.btn-cancel {
  width: 100%;
  background-color: #fff;
  color: #000;
  padding: 12px;
  border: 1px solid #000;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}
.btn-signin:hover {
  background-color: #e15928;
}

/* Account Create CSS */
.createaccount {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 100px;
}
.form-container {
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 740px;
  width: 100%;
}

.form-container p {
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
}

.form-container a {
  color: #007bff;
  text-decoration: none;
}

.form-container a:hover {
  text-decoration: underline;
}

.input-group {
  position: relative;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.input-group .material-symbols-outlined {
  position: absolute;
  left: 12px;
  color: #aaa;
  font-size: 20px;
}

.input-group input {
  width: 100%;
  padding: 12px 12px 12px 40px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}
.form-control .inputStyle {
  padding: 0px !important;
}
.toggle-visibility {
  position: absolute;
  right: 12px;
  cursor: pointer;
}

.password-instructions {
  font-size: 12px;
  color: #666;
  margin-bottom: 20px;
}

.password-instructions ul {
  background-color: #f2f5fc;
  list-style: none;
  padding: 20px;
}

.password-instructions ul li {
  margin-bottom: 5px;
  color: #0094db;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.name-group {
  display: flex;
  gap: 20px;
}

.name-group .input-group {
  flex: 1;
}

.btn-create-account {
  width: 100%;
  background-color: #f26836;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.btn-create-account:hover {
  background-color: #e15928;
}
.form-container h2 {
  font-size: 36px;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}
.form-container p {
  text-align: center;
  font-size: 24px;
  margin-top: 20px;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.form-container h5 {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: #464646;
  font-weight: 400;
  margin-top: 20px;
}
/* Application form CSS */

.applicationform {
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

.section-title {
  margin-top: 30px;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  font-family: "Roboto", sans-serif;
}

.form-control {
  height: 45px;
  border-radius: 5px;
}

.form-group button {
  width: 107px;
  height: 45px;
  background-color: #163365;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 20px;
}
.form-row {
  display: flow-root;
}
.btn-submit {
  display: block;
  width: 300px; /* Adjust the width as per your preference */
  margin: 20px auto; /* Centers the button horizontally */
  background-color: #f26836; /* Custom background color */
  border-radius: 5px; /* Optional: Adds a border radius */
}




.btn-block {
  width: 100%;
}

textarea.form-control {
  resize: none;
}

@media (max-width: 768px) {
  .form-group {
    width: 100%;
  }

  .form-group button {
    margin-top: 10px;
  }
}
/* Style for the label within the form group */
.form-group label {
  display: block; /* Ensures the label is displayed on its own line */
  font-weight: 600; /* Makes the label text bold */
  margin-bottom: 8px; /* Adds space below the label */
  color: #8e8e8e; /* Color for the label text */
  font-size: 18px; /* Sets the font size */
}

.form-control-file {
  padding: 6px;
  border-radius: 4px;
  background-color: #f8f9fa;
}

/* Tabbing CSS */
.tabbing {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-top: 100px;
}
.nav-tabs {
  display: flex;
  /* border-bottom: 2px solid #ddd; */
}
.tab-link {
  display: block;
  padding: 10px 20px;
  color: #163365; /* Default link color */
  background-color: transparent; /* No background by default */
  border-radius: 5px; /* Rounded corners */
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

/* Hover state */
.tab-link:hover {
  color: white !important;
  background-color: #163355 !important; /* Background color on hover */
}

/* Active state */
.tab-link.active {
  color: white; /* Text color for the active tab */
  background-color: #163355; /* Background color for the active tab */
}

/* Optional: Focus and Active states */
.tab-link:focus,
.tab-link:active {
  color: white;
  background-color: #163355;
}
.tab-link {
  text-align: center;
  padding: 28px;
  width: 185px;
  font-size: 24px;
}
/* Inactive state for tabs that are not active */
.nav-tabs .tab-item .tab-link:not(.active) {
  color: #163365; /* Inactive text color */
  background-color: #fff; /* Inactive background color */
}

.full-width-hr {
  border: 3px solid #163365 !important;
  margin: 0; /* No margin */
  padding: 0;
  opacity: 1;
}

.tab-cards {
  margin-top: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.tabrow .col-md-4 {
  margin-top: 50px;
}
.tab-cards img {
  width: 100%;
  /* height: 200px; */
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black with transparency */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease; /* Smooth transition */
  padding: 0; /* Ensure no padding is applied */
  margin: 0; /* Ensure no margin is applied */
  width: 100%; /* Ensure it covers the full width of the image */
  height: 100%; /* Ensure it covers the full height of the image */
}

.col-md-4:hover .overlay {
  opacity: 1;
}

.tab-image {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
}

.search-icon {
  position: relative;
  color: white;
  font-size: 40px;
}
.bestotabbing {
  margin-top: 30px;
  background-color: #163355;
}

.bestotab {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 100px;
}
.bestotab h1 {
  font-size: 72px;
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  color: #fff;
  align-items: center;
}
.bestotab p {
  font-size: 25px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #fff;
}
.tabimages img {
  margin-top: 30px;
  width: 100%;
}
.nav-tabs > li > a:hover {
  border-color: #163355;
  /* border-color: #eee #eee #ddd; */
}
.abc {
  background: url("./Images/background.jpeg") 50% / cover no-repeat,
    var(--Dark, #000000);
}
.main8 {
  padding: 90px 0px;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}
.title123 {
  color: var(--White, #e2dbdb);
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: 66px auto;
  width: 624px auto;
}
.cl1 {
  color: var(--White, #e7e1e1);
  text-align: center;

  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  height: 37px auto;
  width: 286px auto;
}
.cl11 {
  color: var(--White, #eeebeb);
  text-align: center;

  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 19px auto;
  width: 624px auto;
}
.cls1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-direction: row;
}

.cardClass {
  border-radius: 30px;
  border: 2px solid var(--Half-white, rgba(255, 255, 255, 0.12));
  background: var(--Half-white, rgba(255, 255, 255, 0.12));
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 388px;
  height: 378px;
}

.cardimg {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.nameStyle,
.companyStyle {
  margin: 5px 0;
  color: #fff;
}

.contentStyle {
  color: #fff;
  margin-top: 10px;
  font-size: 16px;
  line-height: 1.5;
}

.body {
  align-items: center;
}
.wrapper {
  overflow: hidden;
  align-items: center;
}

.circle {
  width: 200px;
  height: 100px;
  background-color: white;
  border-top-left-radius: 380px;
  border-top-right-radius: 380px;
  border: 15px solid #63ed32;
  border-bottom: 0;
  background-color: transparent;

  transition: all 1s ease;

  transform-origin: bottom;
  position: absolute;
  z-index: 1;
  transform: rotate(-180deg);

  bottom: 0;
  left: -15px;
}

.circle-out {
  background-color: transparent;
  width: 200px;
  height: 100px;
  border-top-left-radius: 380px;
  border-top-right-radius: 380px;
  border: 15px solid #f3ffef;
  border-bottom: 0;
  position: relative;
}
.circle1 {
  width: 200px;
  height: 100px;
  background-color: white;
  border-top-left-radius: 380px;
  border-top-right-radius: 380px;
  border: 15px solid #32d7ed;
  border-bottom: 0;
  background-color: transparent;

  transition: all 1s ease;

  transform-origin: bottom;
  position: absolute;
  z-index: 1;
  transform: rotate(-180deg);

  bottom: 0;
  left: -15px;
}
.circle-out1 {
  background-color: transparent;
  width: 200px;
  height: 100px;
  border-top-left-radius: 380px;
  border-top-right-radius: 380px;
  border: 15px solid #e7fcff;
  border-bottom: 0;
  position: relative;
}
.circle2 {
  width: 200px;
  height: 100px;
  background-color: white;
  border-top-left-radius: 380px;
  border-top-right-radius: 380px;
  border: 15px solid #8b59f5;
  border-bottom: 0;
  background-color: transparent;

  transition: all 1s ease;

  transform-origin: bottom;
  position: absolute;
  z-index: 1;
  transform: rotate(-180deg);

  bottom: 0;
  left: -15px;
}
.circle-out2 {
  background-color: transparent;
  width: 200px;
  height: 100px;
  border-top-left-radius: 380px;
  border-top-right-radius: 380px;
  border: 15px solid #e7dcff;
  border-bottom: 0;
  position: relative;
}
.circle3 {
  width: 200px;
  height: 100px;
  background-color: white;
  border-top-left-radius: 380px;
  border-top-right-radius: 380px;
  border: 15px solid #ef58f2;
  border-bottom: 0;
  background-color: transparent;

  transition: all 1s ease;

  transform-origin: bottom;
  position: absolute;
  z-index: 1;
  transform: rotate(-180deg);

  bottom: 0;
  left: -15px;
}
.circle-out3 {
  background-color: transparent;
  width: 200px;
  height: 100px;
  border-top-left-radius: 380px;
  border-top-right-radius: 380px;
  border: 15px solid #ffecff;
  border-bottom: 0;
  position: relative;
}

.textcircle {
  font-weight: 500;
  font-size: 50px;
  position: absolute;
  left: 54%;
  top: 65%;
  z-index: 1;
  transform: translate(-50%, -50%);
  color: white;
}

/*.containerss{
  display: flex;
max-width: 100%;
padding: 90px 0px;
flex-direction: column;
align-items: center;
gap: 50px;
flex-shrink: 0;
background: url("./Images/Arrow.jpeg") 0px -288.079px / 100% 261.256% no-repeat, var(--Dark, #0F1524);
}
.hh{
  color: var(--White, #FFF);
text-align: center;


font-family: Montserrat;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.row4{
  display: flex;
justify-content: center;
align-items: flex-start;
gap: -10px;
padding: 20px;

}
.cardwrapper{
  display: flex;
padding: 20px;
flex-direction: column;
align-items: flex-start;
gap: 10px;
border-radius: 25px;
background: var(--border, rgba(0, 0, 0, 0.12));
backdrop-filter: blur(8.850000381469727px);
}
.card1{
  display: flex;
height: auto;
padding: 0px 20px;
flex-direction: column;
align-items: center;
gap: 16px;
position: relative;
background-color: transparent;
}
.title{
  display: flex;
flex-direction: column;
align-items: center;
gap: 13px;
}
.n1{
  color: var(--White, #FFF);
text-align: center;


font-family: Lato;
font-size: 17px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.n2{
  color: var(--White, #FFF);
text-align: center;
leading-trim: both;
text-edge: cap;

font-family: Lato;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 146%; 
}
.col-sm-3 {
  margin: 0 10px; 
}  */

/* .container_fluid{
  background: var(--White, #FFF);
  box-shadow: 0px 4px 21.8px 0px rgba(0, 0, 0, 0.10);
}
.container{
  max-width: 100%;
}
.cons1{
  display: flex;
padding: 5px 40px;
justify-content: center;
align-items: center;
gap: 152px;
}
.container img{
  flex-shrink: 0;
  width: 241px;
}*/

.container-fluid .bgcolor {
  background: #fff;
}
.Aboutus {
  padding:50px 0px 0px 0px;
}
.Content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: -74px;
}

.Content h2 {
  margin-bottom: 20px !important;
  font-weight: 500px;
  font-family: "Roboto", sans-serif;
  font-size: 48px;

  line-height: 48px;
}
.abtus {
  /* max-width: 100%; */

  border-radius: 8px;
  width: 100%;
}
.bgcolors1 {
  background-color: #eaf9ff;
}
.about,
.aboutcarrier {
  display: flex;
  /* padding: 100px 0px 90px 0px; */
  flex-direction: column;
  align-items: center;
  gap: 60px;
}
.aboutcarrier::after {
  display: block;
}
.product-list12 {
  padding: 30px;
}
.INFOE1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 17px;
}
.aboutusrow h2 {
  color: #163365;
  /* text-align: center; */
  font-family: "Roboto Slab", serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 20px 0px !important;
}
.aboutusrow img,
.carrierrow img {
  width: 100%;
  height: 100%;
}
.aboutusrow .col-md-8 {
  padding-left: 94px;
}
.aboutusrow p,
.carrierrow p {
  font-family: "Roboto", sans-serif;
  color: #333;
  text-align: justify;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
}
/* .aboutcarrier :hover{
 box-shadow: 0px 0px 22px 0px rgba(15, 91, 163, 0.1);
} */
.carrierrow h2 {
  color: #163365;
  text-align: center;
  font-family: "Roboto Slab", serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  padding-bottom: 20px;
  /* padding-top: 30px; */
}
.Contenth2 {
  color: var(--Dark, #0f1524);
  text-align: center;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.Content p {
  font-family: "Roboto", sans-serif;
  color: #333;
  text-align: justify;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
}
.container-fluid .bgcolour1 {
  background: #fff;
}
.content7 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 50px;
}
.informative {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.informative h2 {
  color: var(--Dark, #0f1524);
  text-align: center;

  /* Title 2 */
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.informative p {
  color: var(--Dark, #0f1524);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
}
.r1 {
  display: flex;
  align-items: flex-start;
  gap: 50px;
}
.strategy1 {
  background-color: #eaf9ff;
}
.strategy {
  display: flex;
  /* padding: 90px 0px; */
  flex-direction: column;
  align-items: center;
  gap: 50px;
}
.strategy h2 {
  color: #163365;
  text-align: center;
  font-family: "Roboto Slab", serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  /* padding-top: 30px; */
}
.listgrp {
  display: flex;
  align-items: flex-start;
}
.info1 {
  height: 180px;
  margin-bottom: 30px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: -13px;
  border-radius: 8px;
  background-color: #fff;
}
.head1 {
  display: flex;
  padding: 10px 10px 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.head1 h4 {
  color: var(--Dark, #0f1524);
  text-align: center;
  font-family: Montserrat;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}
.head1 h2 {
  color: #000;
  text-align: center;
  font-family: "Roboto";
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}
.caption1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.caption1 p {
  color: #000;
  font-family: "Roboto";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 146%;
}
.c1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
}
.WB1 {
  background-color: #eaf9ff;
}
.WB .row {
  display: flex;
  justify-content: center;
  /* align-items: flex-start; */
  gap: 50px;
}
.cd {
  color: var(--Dark, #0f1524);
  text-align: center;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.ct {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--Dark, #0f1524);
  text-overflow: ellipsis;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 146%;
  text-align: justify;
}
/* .btn {
  background-color: #fff;
  display: flex;
  height: 34px;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  border: 1px solid var(--border, rgba(0, 0, 0, 0.12));
  color: var(--Dark-Blue, #015b7e);
  text-align: center;

  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 146%;
} */
.img0 {
  color: #f26836 !important;
  width: 20px;
  height: 20px;
}
.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: -13px;
}
.circletext {
  font-weight: 700;
  color: #f2f2f2;
  font-size: 30px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}
.WB .row {
  display: flex;
  justify-content: center;
  gap: 50px;
  align-items: stretch; /* Ensure all columns stretch to the same height */
}

.col-md-3 {
  display: flex;
  flex-direction: column;
}

.cards {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribute space between items */
  flex-grow: 1; /* Allow cards to grow to fill the available space */
}

.count {
  margin-top: 50px;
  display: flex;
  width: 60px;
  height: 60px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 30px;
  border: 1px solid var(--border, rgba(0, 0, 0, 0.12));
  background: var(--White, #fff);
  color: var(--Dark, #0f1524);
  text-align: center;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.frame {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}
.cards {
  display: flex;
  align-items: center;
  border-radius: 30px;
  background: var(--White, #fff);
  box-shadow: 0px 0px 22.2px 0px rg;
}
.WB .col-md-3 {
  flex: 0 0 auto;
  width: 29%;
}
/* .cardsrategy {
  max-height: 100%;
  display: flex;
  padding: 20px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 30px;
  background: var(--White, #fff);
  box-shadow: 0px 0px 22px 0px rgba(95, 178, 255, 0.1);
}
.count {
  margin-top: 50px;
  display: flex;
  width: 60px;
  height: 60px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 30px;
  border: 1px solid var(--border, rgba(0, 0, 0, 0.12));
  background: var(--White, #fff);
  color: var(--Dark, #0f1524);
  text-align: center;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.Card.Title {
  color: var(--Dark, #0f1524);
  text-align: center;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.frame {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}
.cards {
  display: flex;
  align-items: center;
  border-radius: 30px;
  background: var(--White, #fff);
  box-shadow: 0px 0px 22.2px 0px rg;
}
.WB .col-md-3 {
  flex: 0 0 auto;
  width: 29%;
} */
.cn1 {
  background: var(--White, #fff);
  /* padding: 90px 0px 0px; */
}
/* .CN1 {
  padding: 90px 0px;
} */
.r7 {
  margin-left: 45px;
}
.main7 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 252px;
}

.info111 {
  margin-bottom: 215px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
}
.heading11 {
  display: flex;
  padding: 10px 10px 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.heading11 h1 {
  text-align: center;
  color: var(--Dark, #0f1524);
  font-family: Montserrat;
  font-size: 29px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.heading11 h6 {
  color: var(--dark-80, rgba(15, 21, 36, 0.8));
  text-align: justify;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 146%;
  width: 451px;
}
.graph {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: relative;
  margin-top: 95px;
  padding: 90px 108px 0px 108px;
}
.graphgrp {
  width: 400px;
  display: flex;
  padding: 112px 108px 0px 109px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-bottom: 350px;
}
.largecircle {
  width: 400px;
  height: 400px;
  flex-shrink: 0;
  /* position: absolute; */
  border-radius: 400px;
  background: var(--Thin-Pink, #ffecff);
  box-shadow: 0px 0px 53.5px 28px rgba(3, 146, 212, 0.06);
}
.infor11 {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
}
.infor121 {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
  margin: 150px;
}
.infor11 h1,
.infor121 h1 {
  color: var(--Dark, #0f1524);
  text-align: center;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.infor11 p,
.infor121 p {
  color: var(--Dark, #0f1524);
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
}
.infomain {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
  position: relative;
  margin-top: 70px;
}

.count1 {
  color: var(--Dark, #0f1524);
  text-align: center;
  font-family: Montserrat;
  font-size: 55px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.caption {
  width: 185px;
  height: 19px auto;
  color: var(--Dark, #0f1524);
  text-align: center;

  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.smallcard {
  margin-right: 115px;
  width: 200px;
  display: inline-flex;
  padding: 45.5px 7.5px;
  justify-content: center;
  align-items: center;
  position: absolute;
  /* z-index: 99999; */
  border-radius: 200px;
  right: -18px;
  background: var(--Thin-Neon, #e7fcff);
  box-shadow: 0px 4px 35.8px 0px rgba(95, 178, 255, 0.06);
}
.smallcard1 {
  margin-right: 115px;
  width: 200px;
  display: inline-flex;
  padding: 45.5px 7.5px;
  justify-content: center;
  align-items: center;
  position: absolute;
  /* z-index: 99999; */
  left: 95px;
  border-radius: 200px;
  background: var(--Thin-Neon, #e7fcff);
  box-shadow: 0px 4px 35.8px 0px rgba(95, 178, 255, 0.06);
}

.smallcard2 {
  width: 200px;
  display: inline-flex;
  padding: 45.5px 7.5px;
  justify-content: center;
  align-items: center;
  position: absolute;
  /* z-index: 99999; */
  margin-top: -420px;
  border-radius: 200px;
  background: var(--Thin-Neon, #e7fcff);
  box-shadow: 0px 4px 35.8px 0px rgba(95, 178, 255, 0.06);
}
.WB .card-title {
  color: var(--Dark, #0f1524);
  text-align: center;

  /* Title 2 */
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.WB .card-text {
  overflow: hidden;
  color: var(--Dark, #0f1524);
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.WB h2 {
  color: var(--Dark, #0f1524);
  text-align: center;

  /* Title 2 */
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
/* .WB p{
  color: var(--Dark, #0F1524);
text-align: center;
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 145%; 
} */
@media screen and (max-width: 500px) {
  .accordion p {
    font-size: 17px;
  }
  .processframe {
    flex-wrap: wrap;
  }
  col-sm-3 {
    flex: 0 0 auto;
    width: 50%;
  }
  .wwd .col {
    width: 100%;
    flex: auto;
  }
  .WB .col-md-3 {
    width: 100%;
  }
  /* .main {
  } */
  .container-fluid .main {
    justify-content: normal;
    font-size: 25px;
  }
  /* .col-md-8 p{
    font-size: 10px;
  } */
  .col-md-4 {
    margin-top: 20px;
  }
  /* .but1 {
    padding: 31px 7px;
  } */
  /* .container-fluid .main h1{
    font-size: 48px;
  } */
  .bannerbg p {
    font-size: 20px;
  }
  .bannerbg h1 {
    font-size: 50px;
    font-family: Inter;
  }

  .eng {
    color: var(--Dark, #313235);
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
  }
  .card1 {
    padding: 0px 33px;
  }
  /* .row {
    gap: 20px;
  } */
  .contentStyle {
    font-size: 12px;
  }
  .nameStyle {
    font-size: 13px;
  }
  .companyStyle {
    font-size: 13px;
  }
  .frm1 p {
    font-size: 17px;
  }
  /* .form {
    margin-top: 600px;
  } */
  .container-fluid .main h1 {
    font-size: 40px;
  }
  .banner1 {
    display: none;
  }
  /* .input-container {
  } */

  .input-group {
    flex-wrap: nowrap !important;
    position: relative;
    display: flex;
    align-items: stretch;
    width: 100%;
  }
  .input-group1 {
    flex-wrap: nowrap !important;
    position: relative;
    display: flex;
    align-items: stretch;
    width: 100%;
  }
  ._input-group.scss {
    position: relative;
    display: flex;
    flex-wrap: nowrap !important;
    align-items: stretch;
    width: 100%;
  }

  /* .input-group .form-control {
    flex: 1; 
  } */
  .input-container input[type="text"],
  input[type="email"],
  input[type="number"] .Bimg {
    height: 50px;
  }

  .socialwrapper .logos {
    flex-wrap: wrap;
    justify-content: center;
  }
  .largecircle {
    height: 250px;
    width: 250px;
  }
  .infor121 {
    margin: 70px;
  }
  .infor11 h1 {
    font-size: 21px;
  }
  .smallcard,
  .smallcard1,
  .smallcard2 {
    height: 120px;
    width: 120px;
    border-radius: 120px;
  }
  .smallcard2 {
    margin-top: -283px;
  }
  .smallcard1 {
    left: 203px;
  }
  .smallcard {
    right: 102px;
  }
  /* .CN1 {
    padding: 0px;
  } */

  /* .companylistwrapper {
    margin-right: 175px;
  } */
  /* .companylistwrapper2 {
    margin-left: -257px;
  }
  .supportlistwrapper {
    margin-left: -256px;
  } */
  /* .logos {
    margin-left: -84px;
  } */
  .graphgrp {
    width: 100%;
  }
  .graph {
    margin-right: 40px;
  }
  /* .rowphp{
    margin-left: 75px;
  } */
  .Javaimg {
    margin-top: 20px;
    max-width: 100%;
  }
  .javarow {
    margin-left: 32px;
  }
  .seorow {
    margin-left: 32px;
  }
}

/* .cn1{
  background: var(--White, #FFF);
}
.graphdetails{
  display: flex;
padding: 90px 0px;
justify-content: center;
align-items: center;
gap: 252px;
}
.information1{
  display: flex;
flex-direction: column;
align-items: flex-start;
gap: 1px;
}
.heading{
  display: flex;
padding: 10px 10px 10px 0px;
justify-content: center;
align-items: center;
gap: 10px;
}
.heading h2{
  color: var(--Dark, #0F1524);
font-family: Montserrat;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.information1 p{
  color: var(--dark-80, rgba(15, 21, 36, 0.80));
leading-trim: both;
text-edge: cap;
font-family: Lato;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 146%;
}
.graph{
  display: flex;
  padding: 112px 100.5px 0px 109.5px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: relative;
}
.graphgrp{
  display: flex;
  padding: 112px 108.5px 0px 109.5px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-bottom: 350px;
}
.smallcircle1{
  width: 200px;
  display: inline-flex;
  padding: 45.5px 7.5px;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 99999;
  border-radius: 200px;
  right: 0;
  background: var(--Thin-Neon, #e7fcff);
  box-shadow: 0px 4px 35.8px 0px rgba(95, 178, 255, 0.06);
}
.circleinfo{
  border-radius: 200px;
background: var(--Thin-Neon, #E7FCFF);
box-shadow: 0px 4px 35.8px 0px rgba(95, 178, 255, 0.06);
}
.circleinfo h2{
  color: var(--Dark, #0F1524);
text-align: center;
font-family: Montserrat;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.circleinfo p{
  color: var(--Dark, #0F1524);
text-align: center;
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 145%;
}
.largecircle{
  margin-right: 162px;
  width: 400px;
height: 400px;
flex-shrink: 0;
position: absolute;
border-radius: 400px;
background: var(--Thin-Pink, #FFECFF);
box-shadow: 0px 0px 53.5px 28px rgba(3, 146, 212, 0.06);
}
.circleinfo1{
flex-direction: column;
align-items: center;
gap: 7px;
margin-top: 150px;
}
.circleinfo1 h2{
  color: var(--Dark, #0F1524);
text-align: center;
font-family: Montserrat;
font-size: 55px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.circleinfo1 p{
  color: var(--Dark, #0F1524);
text-align: center;
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 145%; 
}
.smallcircle2{
  width: 200px;
  display: inline-flex;
  padding: 45.5px 7.5px;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 99999;
  right: 413px;
  border-radius: 200px;
  background: var(--Thin-Neon, #e7fcff);
  box-shadow: 0px 4px 35.8px 0px rgba(95, 178, 255, 0.06);
}
.smallcircle3{
  margin-right: 180px;
  width: 200px;
  display: inline-flex;
  padding: 45.5px 7.5px;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 99999;
  margin-top: -420px;
  border-radius: 200px;
  background: var(--Thin-Neon, #e7fcff);
  box-shadow: 0px 4px 35.8px 0px rgba(95, 178, 255, 0.06);
} */

.dropdown-content p:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* select {
  display: flex;
  height: 50px;
  width: 320px;
  padding: 10px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid var(--border, rgba(0, 0, 0, 0.12));
  background: var(--White, #fff);
} */

.Headerpro h1 {
  font-size: 30px;
  animation-fill-mode: both;
  animation-duration: 1000ms;
  animation-delay: 0ms;
  animation-iteration-count: 1;
  opacity: 1;
  animation-name: react-reveal-353339736001772-1;
}

.Wrap {
  overflow: hidden;
  margin-top: 60px;
}
.content {
  color: black;
  margin-bottom: 20px;
}
.Wrap h3 {
  color: #0a8fce;
  text-align: justify;
  font-size: 23px;
}
.Wrap p {
  text-align: justify;
  margin-bottom: 1rem;
  margin-top: 0;
}
/* .java{
  display: flex;
  flex-wrap: wrap;
}
.javacard{
  background-clip: initial;
    background-color: var(--bs-card-bg);
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
    display: flex;
    flex-direction: column;
    height: var(--bs-card-height);
    min-width: 0;
    position: relative;
    padding-bottom: 15px;
    width: 300px;
    align-items: center;
    overflow: hidden;
    margin: 20px;
    padding: 16px;
}
.javaimg{
  margin-top: 45px;
  height: 80px;
    padding: 5px;
    width: 80px;
}
.javatitle{
  color: #0a8fce;
    font-size: 17px;
}
.javacard .javatext {
  display: none;
}

.javacard:hover .javatext {
  display: block;
}
.javacard:hover .javatitle {
  color: red;
} */

.headerpro {
  height: 50vh;
  background-color: #254441;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.react-reveal {
  color: #fff;
  opacity: 0;
  animation: fadeInUp 1s ease-in-out forwards;
}

.javacard {
  display: flex;
  flex-direction: column;
  height: 200px;
  width: 200px;
  overflow: hidden;
  position: relative;
}

.javatitle {
  color: #0a8fce;
  font-size: 14px;
  position: absolute;
  /* top: 240px;  */
  left: 10px; /* Adjust left position as needed */
  z-index: 1; /* Ensure title appears above image */
  /* Title text color */
}

.javacard .javatext {
  margin-top: 50px;
  display: none; /* Hide text by default */
}

.javacard:hover {
  height: auto; /* Set height to auto on hover to display full content */
  position: relative; /* Reset position to relative */
  z-index: 1; /* Ensure hovered card stays on top */
  transform: scale(1.05);
}

.javacard:hover .javatext {
  display: block; /* Show text on hover */
}

/* Assuming the image is a child element of .javacard */
.javacard img {
  padding-top: 20px;
  width: 80px; /* Set width to 100% to ensure it fills the container */
  height: 80px; /* Set height to 100% to ensure it fills the container */
  object-fit: cover; /* Ensure the image covers the entire container */
}
.java h3 {
  text-align: justify;
  font-size: 23px;
  color: #0a8fce;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 25px;
  margin-top: 0;
}
.java {
  margin-top: 50px;
}
.java p {
  text-align: justify;
  font-size: 16px;
  margin-bottom: 0.5rem;
}
.java img {
  background-size: auto;
  height: auto;
  max-height: 500px;
  max-width: 100%;
}
.java p {
  margin-bottom: 1rem;
  margin-top: 0;
  text-align: justify;
}
.java p strong {
  color: rgb(242, 104, 54);
}
.r10 {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}
.JavaCard1 {
  display: flex;
  flex-direction: column;
  height: var(--bs-card-height);
  min-width: 0;
  position: relative;
  padding-bottom: 15px;
  margin: 20px;
  padding: 16px;
  border-radius: 0;
}
.JavaCard1 img {
  height: 100px;
  width: 100px;
}
.JavaCard1 title {
  color: #000;
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin: 10px 0;
}
.JavaCard1 .card-text {
  color: #07355c;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
}
.Javaimg {
  margin-top: 20px;
  max-width: 100%;
}
.javacard:hover .javatitle {
  color: red;
}
.react h3 {
  color: #0a8fce;
  text-align: justify;
  font-size: 23px;
  margin-top: 40px;
}
.react p {
  text-align: justify;
  margin-bottom: 1rem;
  margin-top: 0;
}

.flip-card {
  margin-top: 20px;
  float: left;
  font-size: 1.2em;
  -webkit-perspective: 800px;
  perspective: 800px;
  transition: all 0.3s ease 0s;
  width: 30.7%;
  background-color: transparent;
  width: 300px;
  height: 300px;
  perspective: 1000px;
}

.flip-card-inner {
  background: #f4f1f1;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  backface-visibility: hidden;
  box-sizing: border-box;
  color: #000;
  display: block;
  font-size: 1.2em;
  height: 100%;
  padding: 0.8em;
  position: absolute;
  text-align: center;
  width: 100%;
}
.flip-card-front h1 {
  margin: 0 0 5%;
  padding: 0.5em;
  text-align: center;
  color: #163365;
  font-family: "Roboto Slab", sans-serif;
  font-size: 30px;
}
.flip-card-front h3 {
  margin-top: 0.5rem !important;
  font-size: 23px;
  color: #143774;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.flip-card-back {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
}
.flip-card-back p {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  padding-right: 44px;
}
.flip-card h3 {
  color: #163365;
  font-family: "Roboto Slab", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
}

.react p {
  text-align: justify;
  margin-bottom: 10px;
  margin-top: 10px;
}
.react p {
  margin-bottom: 1rem;
  margin-top: 0;
  text-align: justify;
}
.react p strong {
  color: rgb(242, 104, 54);
}
.Reactjs {
  margin-top: 40px;
  background-size: auto;
  height: auto;
  max-height: 500px;
  max-width: 100%;
}

.reactcontainer {
  display: flex;
  padding: 50px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.reactrow {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
}
.reactcards {
  background: #fff;
  border-radius: 10px;
  margin: 0 10px 40px;
  overflow: hidden;
  padding: 10px;
  position: relative;
  text-align: center;
  transition: all 0.3s ease-in-out;
  width: 100%;
  height: 350px;
}

.reactcards .card-img {
  height: 100px;
  width: 165px;
}
.reactcards .card-title {
  color: #0a8fce;
  font-weight: 700;
  margin-bottom: 15px;
}
.reactcards .card-text {
  text-align: left;
  font-size: 14.5px;
  margin-bottom: 1rem;
  margin-top: 0;
}
.reactcards:hover .reacttitle {
  color: red;
}

.reactcards:hover {
  height: 350px;
  position: relative;
  /* z-index: 1;  */
  transform: scale(1.13);
}
.equal-height-row {
  display: flex;
}

.equal-height-row .col-md-3 {
  flex: 1;
}
.reactimg {
  margin-top: 30px;
}
.phpimg {
  max-width: 100%;
}
.android h3 {
  color: #0a8fce;
  text-align: justify;
  font-size: 23px;
  margin-top: 40px;
}
.android p {
  margin-bottom: 1rem;
  margin-top: 0;
  text-align: justify;
}
.android p strong {
  color: rgb(242, 104, 54);
}
.androidimg {
  margin-top: 20px;
  background-size: auto;
  height: auto;
  max-height: 500px;
  max-width: 100%;
}
.androidimg1 {
  margin-top: 20px;
  background-size: auto;
  height: auto;
  max-height: 500px;
  max-width: 100%;
  margin-left: 100px;
}
.androidservice {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
  width: 100%;
  height: 110px;
  margin: 0 10px 40px;
  overflow: hidden;
  padding: 30px;
  position: relative;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
.androidservice h4 {
  font-size: 22px;
  color: #0a8fce;
}
.androidservice:hover .androidtitle {
  color: red;
}

.androidservice:hover {
  height: auto;
  position: relative;
  z-index: 1;
  transform: scale(1.13);
}
.androidcards {
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  padding-bottom: 15px;
  width: 100%;
  height: 180px;
  margin: 20px;
  padding: 16px;
  border-radius: 0;
}
.androidcontent {
  padding: 1rem;
}
.androidcontent .card-title {
  color: #000;
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 0;
  text-transform: capitalize;
  margin: 10px 0px;
}
.androidcontent .card-text {
  text-align: center;
  font-size: 15px;
  color: #143774;
  font-weight: 700; /* Adjust the font weight as needed */
}
.agile {
  background-size: auto;
  height: auto;
  max-height: 500px;
  max-width: 100%;
}
.headerpro {
  font-family: Montserrat;
  height: 50vh;
  background-color: #254441;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
    /* Move 20 pixels down */
  }

  100% {
    opacity: 1;
    transform: translateY(0);
    /* Move back to original position */
  }
}

.react-reveal {
  font-family: Montserrat;
  font-weight: 700;
  color: #fff;
  opacity: 0;
  animation: fadeInUp 1s ease-in-out forwards;
}

.content > h3 {
  color: #0a8fce;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 23px;
  margin-top: 50px;
}

.content > p {
  font-size: 16px;
  font-family: Lato;
  text-align: justify;
  font-weight: 400;
}

.content > p > strong {
  color: rgb(242, 104, 54);
}

ul > li {
  font-size: 18px;
  /* font-family: Lato; */
  text-align: justify;
  font-weight: 400;
}
.content > ul > li > p > strong {
  color: rgb(242, 104, 54);
}

/* .card {
  height: 16rem;
  font-family: Open Sans, sans-serif !important;
  background-color: #fff;
  border-radius: .25rem;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, .25);
  overflow: hidden;
}

.card>div>p {
  color: #07355c;
  font-weight: 300;
  font-size: small;
  height: 4rem;

} */
.singlecard {
  margin-left: 2%;
  margin-top: 6%;
  margin-right: 1%;
  border-radius: 0;
}
.seoimg {
  height: 100px;
  width: 100px;
}
.seoimg1 {
  background-size: auto;
  height: auto;
  max-height: 500px;
  max-width: 100%;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.h3-head {
  font-size: 23px;
  color: #0a8fce;
  font-family: Lora, serif;
  margin-top: 20px;
}
.phpimg {
  background-size: auto;
  max-width: 100%;
  max-height: 500px;
  vertical-align: middle;
}
.headerpro {
  height: 50vh;
  background-color: #254441;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.react-reveal {
  color: #fff;
  opacity: 0;
  animation: fadeInUp 1s ease-in-out forwards;
}
.Wrap {
  margin: 50px 0;
  margin: 5%;
  text-align: justify;
}
.myBtn {
  color: red;
  cursor: pointer;
}

strong {
  color: rgb(242, 104, 54);
}


.cardphp {
  /* list-style: none; */
  height: 85%;
  width: 100%;
  /* padding: 0; */
  align-items: center;
  margin: 20px;
  padding: 15px;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  /* min-height: 220px; */
  gap: 10px;
  border-radius: 10px;
  text-align: justify;
  /* border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius); */
}
.card-content {
  text-align: center;
}
.cardphp .card-img-top {
  height: 80px;
  margin-top: -45px;
  padding: 5px;
  width: 80px;
}

.card-content p {
  color: #07355c;
  font-weight: 300;
  text-align: justify;
}
/* .card-text{
  color: #07355c;
  font-weight: 500;
  font-size: 15px;
} */
.cards-item {
  list-style: none;
}
.content,
.wrap {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
}
/* .content p{
  margin: 30px 0;
} */
.h4-head {
  color: #0a8fce;
  font-size: 17px;
  text-transform: uppercase;
  text-align: center;
}
.row {
  /* align-items: stretch; */
  justify-content: space-evenly;
}

.blogmainwrap .row{
  justify-content:flex-start;
  }

  
  
.ibpimg {
  background-size: auto;
  height: auto;
  max-height: 500px;
  max-width: 100%;
}
.logo-title {
  font-size: 18px;
  letter-spacing: 1px;
  color: #143774;
}
.img-box img {
  border-radius: inherit;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.img-box {
  border-radius: 10px;
  height: 100%;
  width: 100%;
}

.center {
  margin: 5%;
}
.cardhis {
  display: grid;
  grid-gap: 1.5rem;
  background: #fff;
  border-radius: 15px;
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 1fr 1fr;
  padding: 1rem;
  transition: 0.3s ease-in-out;
  width: 100%;
}
.cardhis .contenthis {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1rem;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.h3-head {
  text-align: justify;
}
.angular-card > .col {
  padding: 0.4rem;
  width: 15rem;
}
.single-card1 {
  height: 18rem;
}

.single-card1 .card-title {
  font-size: small;
  /* height: 20%; */
}

.single-card1 .card-text {
  font-size: 16px;
  font-family: Lato;
  text-align: justify;
  font-weight: 400;
}

.digital,
.digital1 {
  display: flex;
  padding: 90px 0px 90px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.digitalrow,
.dotnetrow {
  display: flex;
  justify-content: center;
  align-items: center;
}
.digitalrow h2 {
  color: var(--Dark, #0f1524);
  font-family: Montserrat;
  font-size: 55px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: Montserrat;
  font-size: 47px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.digitalrow h2 span {
  color: #0a8fce;
}
.digital1 h2 {
  color: var(--Dark, #0f1524);
  text-align: center;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.digital1 p {
  color: var(--Dark, #0f1524);
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
}
.digital1 button {
  font-size: 18px;
  display: flex;
  height: 60px;
  padding: 10px 30px;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  gap: 5px;
  border-radius: 15px;
  border: 1px solid var(--Dark, #0f1524);
  background: var(--White, #fff);
  box-shadow: 2px 5px 0px 0px var(--Dark, #0f1524);
}
.dgcolor {
  background: var(--Light-blue, #eaf9ff);
}
.digitalframe1 {
  display: flex;
  width: 1056px;
  justify-content: space-between;
  align-items: center;
}
.digital .card {
  height: 393px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  background: linear-gradient(131deg, #dff5ff 11.38%, #f8fdff 35.41%);
  box-shadow: 0px 4px 30.9px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 30px;
  width: 100%;
}

.digitalspan {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  fill: var(--White, #fff);
}
.digitalspan .card-img {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}
.digitalspan {
  width: 100px;
  padding: 32px 0px 0 27px;
  height: 100px;
  background: #fff;
  border-radius: 50%;
}
.digitalspan1 {
  width: 175px;
  padding: 25px 0 0 25px;
  height: 175px;
  background: #fff;
  border-radius: 50%;
  margin-bottom: 10px;
}
.digital .span .card-img {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 30px;
  top: 30px;
}
.digitalspan .img {
  display: flex;
  width: 40px;
  height: 40px;
}
.digital {
  color: var(--Dark, #0f1524);
  text-align: left;
  font-family: Lato;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.digitalspan .card-title {
  color: var(--Dark, #0f1524);
  text-align: left;
  font-family: Lato;
  font-size: 17px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.digital .card-text {
  color: var(--Dark, #0f1524);
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 146%;
  text-align: justify;
}
/* .process{
  display: flex;
padding: 90px 0px;
flex-direction: column;
justify-content: center;
align-items: center;

} */
.processframe {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 120px;
}
.process1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.spanprocess1 {
  width: 400px;
  height: 400px;
  background-color: #fff;
  border-radius: 50%;
}
.d111 {
  padding: 0px 5px 0px 0px;
  height: auto;
  /* height: 48px; */
  width: 130px;
}
.seoimg {
  height: 48px;
  width: 48px;
  color: #015b7e;
}
.processframe h4 {
  color: var(--Dark, #0f1524);
  text-align: center;

  /* Heading 1 */
  font-family: Lato;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.warantycards {
  display: flex;
  padding: 70px 55px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.digitalframe1 h2 {
  color: var(--Dark, #0f1524);
  text-align: center;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.digitalservices {
  display: flex;
  height: 946px;
  justify-content: center;
  align-items: center;
}
.digitalframe1 {
  margin-left: 30px;
  display: inline-flex;
  height: 946px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.digitalframe2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Allow items to wrap */
  justify-content: center; /* Center items horizontally */
}
.digital2 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Align items horizontally */
  margin-bottom: 20px;
}
.processspan1 {
  width: 140px;
  padding: 28px 15px 15px 28px;
  height: 140px;
  background: rgba(10, 143, 206, 0.12);
  border-radius: 50%;
  margin-bottom: 10px;
}
.d11 {
  display: flex;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  left: 30px;
  top: 30px;
}
.process1 h4 {
  color: #333;
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700; 
  /* text-align: justify; */
}
.node {
  display: flex;
  padding: 50px 21px 50px 21px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.noderow {
  display: flex;
  justify-content: center;
  align-items: center;
}
.noderow h2 {
  color: #163365;
  font-family: Montserrat;
  font-size: 72px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-family: "Roboto Slab";
  font-style: normal;
}
.nodecolor {
  background-color: #eaf9ff;
}
.nodecolor h2 {
  color: #163365;
  /* color: var(--Dark, #0F1524); */
  text-align: center;
  font-family: "Roboto Slab";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.nodecolor p {
  color: #333;
  /* text-align: center; */
  font-family: "Roboto";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
}
.nodecolor button {
  display: flex;
  height: 60px;
  padding: 10px 30px;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  gap: 5px;
  border-radius: 15px;
  border: 1px solid var(--Dark, #0f1524);
  background: var(--White, #fff);
  /* box-shadow: 2px 5px 0px 0px var(--Dark, #0F1524); */
}
.nodegraph {
  display: flex;
  padding: 90px 21px 90px 21px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.nodegraph h2 {
  color: var(--Dark, #0f1524);
  text-align: center;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.nodecolor1 {
  background-color: #ddedff;
}
.nodecolor1 h2 {
  font-family: "ROBOTO SLAB";
  color: #163365;
  /* color: var(--Dark, #0F1524); */
  text-align: center;
  /* font-family: Montserrat; */
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
h2 {
  /* text-align: center; */
  font-size: 65px;
  color: black;
  margin-bottom: 10px;
}
.benifitlist {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
.nodelist {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-items: stretch;
  gap: 17px;
}
.nodelist1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.nodeinfo {
  min-height: 287px;
  margin-bottom: 10px;
  max-width: 100%;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: -13px;
  /* border-radius: 92px; */
  background: var(--White, #fff);
}
.process h2 {
  font-family: "ROBOTO SLAB";
  color: #163365;
  /* color: var(--Dark, #0F1524); */
  text-align: center;
  /* font-family: Montserrat; */
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px !important;
  padding-bottom: 50px;
}
.nodecontent {
  display: flex;
  /* align-items: center; */
  gap: 24px;
}
.nodespan1 {
  width: 100px;
  border-radius: 50%;
  background-color: #eaf9ff;
  padding: 22px;
}
.dotnetspan1 {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: #eaf9ff;
  padding: 21px;
}
.noded1 {
  height: 40px;
  width: 40px;
}
.subinfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: -12px;
}

.subinfo h2 {
  color: #163365;
  padding: 10px 10px 10px 0px;
  /* color: var(--Dark, #0F1524); */
  text-align: left;
  font-family: "Roboto Slab";
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.subinfo p {
  color: var(--Dark, #0f1524);
  font-family: "Roboto";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 146%;*/
}
.nodeservicegrp {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nodeframe1 {
  flex-shrink: 0;
  flex-direction: row;
  display: flex;
  gap: 400px;
  padding: 90px 0px 90px 0px;
}
.nodeframe2 {
  flex-shrink: 0;
  flex-direction: row;
  display: flex;
  gap: 400px;
  padding: 90px 0px 90px 0px;
}
.nodeframe {
  flex-shrink: 0;
  flex-direction: row;
  display: flex;
  gap: 350px;
}
.nodecard {
  display: inline-flex;
  padding: 50px 10px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 30px;
}
.nodecard img {
  height: 80px;
  width: 80px;
}
.nodecard3 h4 {
  color: var(--Dark, #0f1524);
  text-align: center;

  /* Title 3 */
  font-family: Montserrat;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
}
.nodegraph button {
  display: flex;
  height: 60px;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 15px;
  border: 1px solid var(--Dark, #0f1524);
  background: var(--White, #fff);
  box-shadow: 2px 5px 0px 0px var(--Dark, #0f1524);
}
.native1,
.native,
.android,
.java,
.java1,
.php,
.sapbusiness {
  display: flex;
  padding: 50px 0px 50px 0px;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
}
.javabenifits,
.javaproject {
  padding: 50px 0px 50px;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  display: flex;
}
.javaproject p {
  text-align: center;
}

.nativerow h1, .java h1{ font-size: 72px;  font-weight: 700;    color: #163365;}
.nativerow h2{ margin-bottom: 20px !important;}

.javabenifits h2{ text-align: center;}
.native,
.android,
.java1 {
  align-items: center;
}
.nativerow,
.javarow {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nativerow p {
  font-size: 16px;
}

.conclusionsec p, .conclusionsec h2{ text-align: left;}

.javarow h2 {
  color: #163365;
  /* color: var(--Dark, #0F1524); */
  font-family: Roboto Slab, serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  margin: 0px !important;
}
.nativerow .col-md-7 img {
  width: 100%;
}
.nativecolor,
.javacolor {
  background-color:#ddedff;
}
.nativecolor h2,
.java1 h2 {
  color: #163365;
  /* color: var(--Dark, #0F1524); */
  font-family: Roboto Slab, serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  margin: 0px !important;
}
.nativecolor p,
.java1 p {
  color: #333;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  padding-right: 44px;
}
.native h2,
.sapbusiness h2 {
  font-family: "ROBOTO SLAB";
  color: #163365;
  /* color: var(--Dark, #0F1524); */
  text-align: center;
  /* font-family: Montserrat; */
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px !important;
  /* padding-bottom: 100px; */
}
.sapbusiness p {
  font-size: 18px;
  text-align: center;
}
.subinfo1 h2 {
  font-size: 20px;
}
.vl {
  border-left: 6px solid green;
  height: 500px;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  top: 0;
}
.nativeframe,
.nativeframe1 {
  display: inline-flex;
  align-items: flex-start;
  flex-direction: row;
}
.angularframe {
  margin-top: 60px;
  gap: 100px;
  display: inline-flex;
  align-items: flex-start;
  flex-direction: row;
}
.angularframe1 {
  gap: 90px;
  display: inline-flex;
  align-items: flex-start;
  flex-direction: row;
}
.angularcontent h3 {
  font-family: "Roboto";
  color: #333;
  font-size: 18px;
}
.nativecontent {
  display: flex;
  width: 280px;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.nativecontent p{ text-align: center;}
.nativespan {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  padding: 25px;
  background-color: #eaf9ff;
}
.angularcontent {
  display: flex;
  width: 280px;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.angularspan {
  border-radius: 50%;
}

.nativeimg {
  height: auto;
  padding: 11px 0px 0px 20px;
  /* height: 48px; */
  width: 79px;
}
.nativecontent h3 {
  padding-top: 20px;
  color: #163365;
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  text-align: justify;
}
.nativecontent p {
  letter-spacing: 0px !important;
  /* padding-top: 20px; */
  color: #333;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  /* text-align: justify;*/
}
.native button,
.java1 button {
  font-family: "Roboto";
  font-size: 18px;
  color: #f26836;
  /* margin-left: 40px; */
  display: flex;
  height: 60px;
  padding: 10px 30px;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  gap: 5px;
  border-radius: 15px;
  border: 1px solid #f26836;
  background: var(--White, #fff);
  box-shadow: none !important;
}
.android h2,
.php h2 {
  color: #163365;
  text-align: center;
  font-family: "Roboto Slab", serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.subinfo1 h2,
.sapsubinfo h2 {
  color: #333;
  text-align: start;
  font-family: "Roboto Slab", serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.phpsubinfo1 h2 {
  color: var(--Dark, #0f1524);
  text-align: start;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.subinfo1 p,
.sapsubinfo p {
  padding-top: 20px;
  color: #333;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  text-align: justify;
}
.nodeinfo {
  background-color: #eaf9ff;
}
.nativerow .col-md-6 img {
  width: 100%;
}
.android p {
  /* text-align: center; */
  color: var(--Dark, #0f1524);
  font-family: "Roboto";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 146%;
}
/* .androidrow{
  display: flex;
}
.androidframe{
  display: flex;
flex-direction: column;
align-items: flex-start;
}
.CaptioN0{
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 146%;
  opacity: 0.8;
  gap: 15px;
  padding: 10px;
}
.CaptioN0 img{
  width: 24px;
  height: 24px;
  
}
.androidinfo h4{
  color: var(--Dark, #0F1524);
font-family: Montserrat;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 140%;
}
.androidinfo p{
  color: var(--Dark, #0F1524);
font-family: Lato;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.androidrow1{
  display: flex;
align-items: flex-start;
}
.nodeimg{
  height: 514px;
    max-width: 100%;
}
.androidrow button{
  display: flex;
  height: 60px;
  padding: 10px 30px;
  justify-content: center; 
  align-items: center; 
  gap: 5px;
  border-radius: 15px;
  border: 1px solid var(--Dark, #0F1524);
  background: var(--White, #FFF);
  box-shadow: 2px 5px 0px 0px var(--Dark, #0F1524);
} */
.sapmonitoring,
.saphana {
  padding: 100px 0px;
}
.androidframe {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.CaptioN0 {
  display: flex;
  flex-direction: column;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 146%;
  opacity: 0.8;
  gap: 15px;
  padding: 10px;
}

.androidinfo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 15px;
}

.CaptioN0 img {
  width: 35px;
  height: 35px;
}

.androidinfo h4 {
  color: #333;
  font-family: "Roboto Slab", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
}

.CaptioN0 p {
  margin: 0px 0px 0px 65px !important; /* Adjust this margin to align with the h4 and image */
}

.nodeinfo1 {
  margin-bottom: 20px;
  height: 320px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: -13px;
  border-radius: 8px;
  background: var(--Light-blue, #eaf9ff);
  max-width: 100%;
}
.phpinfo {
  margin-bottom: 20px;
  height: 300px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: -13px;
  border-radius: 8px;
  background: var(--Light-blue, #eaf9ff);
  max-width: 100%;
}
.javainfo1 {
  margin-bottom: 20px;
  height: 153px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: -13px;
  border-radius: 8px;
  background: var(--Light-blue, #eaf9ff);
  max-width: 100%;
}
.androidstack {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 84px;
  flex-wrap: wrap;
}
.androidstack1 {
  display: flex;
  padding: 90px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.androidstack1 h2 {
  color: #163365;
  text-align: center;
  font-family: "Roboto Slab", serif;
  font-size: 72px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.ios1 {
  gap: 10px;
}
.iosimg {
  height: 736px;
  max-width: 100%;
}
.android button {
  font-family: "Roboto";
  font-size: 18px;
  color: #f26836;
  /* margin-left: 40px; */
  display: flex;
  height: 60px;
  padding: 10px 30px;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 15px;
  border: 1px solid #f26836;
  background: var(--White, #fff);
  box-shadow: none !important;
}

/* styles.css */

.accordion-container {
  display: flex;
  flex-direction: column;
}

.accordion {
  border-radius: 10px;
  margin-bottom: 40px;
  overflow: hidden;
}

.accordion-header {
  display: flex;
  padding: 20px 20px 20px;
  font-size: 25px;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  gap: 20px;
  position: relative;
  align-items: flex-start;
  background: var(--White, #f9fdff);
}
.accordion-header span {
  height: 10px;
}
.accordion-body {
  display: none;
  background: var(--White, #f9fdff);
}

.accordion-checkbox {
  display: none;
}

.accordion-checkbox:checked + .accordion-header + .accordion-body {
  display: block;
}
.custom-list {
  background: var(--White, #f9fdff);
}
.custom-list li {
  margin-bottom: 20px;
}
.custom-list li:last-child {
  margin-bottom: 0;
}
.accordion-header span {
  color: var(--Dark, #080b15);
  font-family: Montserrat;
  font-size: 27px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  gap: 10px;
}
.abcs {
  display: block;
}
.SEO {
  align-items: center;
  display: flex;
  padding: 90px 0px 90px 0px;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
}
.seo {
  background-color: #ddedff;
}

.accordion-checkbox:checked + .accordion-header + .accordion-body {
  /* max-height: 500px; */
  transition: max-height 0.5s ease-in;
}

.accordion-checkbox:checked + .accordion-header .arrow {
  transform: rotate(-135deg);
}

.custom-list li {
  margin-bottom: 20px;
}

.custom-list li:last-child {
  margin-bottom: 0;
}

.arrow {
  width: 10px;
  height: 10px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  transition: transform 0.3s ease-in-out;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}

.accordion p {
  color: var(--Dark, #0f1524);
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 146%;
}
.resourcecolor,
.rpacolor,
.sapcolor {
  background-color: #ddedff;
}
.resourceinfo,
.rpainfo,
.rpa2,
.sapinfo,
.sapibp {
  display: flex;
  padding: 50px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  font-family: Montserrat;
}
.sapinfo .row {
  display: flex;
  /* align-items: center; */
}

.sapinfo .col-md-5 {
  display: flex;
  justify-content: center; /* Center horizontally */
  /* Center vertically */
}
.sapinfo .col-md-5 img {
  width: 100%;
}
.resourceinfo h2,
.rpainfo h2,
.rpa2 h2 {
  color: var(--Dark, #0f1524);
  text-align: center;
  font-family: Montserrat;
  font-size:48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.sapinfo h2 {
  color: #163365;
  /* text-align: left; */
  /* color: var(--Dark, #0F1524); */
  font-family: Roboto Slab, serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  margin: 0px !important;
}
.resourceinfo p,
.rpainfo p,
.rpa2 p,
.saprow p {
  color: var(--Dark, #0f1524);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  font-family: Lato;
}
.androidinfo p {
  color: var(--Dark, #0f1524);
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  font-family: Lato;
}
.intern {
  display: flex;
  padding: 90px 0px 90px 0px;
  flex-direction: column;
  justify-content: center;
  gap: 36px;
}
.intern1 {
  display: flex;
  padding: 90px 10px 90px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.internrow p {
  color: var(--Dark, #0f1524);
  text-align: justify;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
}
.intern1 button,
.sapinfo button {
  font-family: "Roboto";
  font-size: 18px;
  color: #f26836;
  /* margin-left: 40px; */
  display: flex;
  height: 60px;
  padding: 10px 30px;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  gap: 5px;
  border-radius: 15px;
  border: 1px solid #f26836;
  background: var(--White, #fff);
  box-shadow: none !important;
  /* box-shadow: 2px 5px 0px 0px var(--Dark, #0F1524); */
}
.internrow {
  display: flex;
  justify-content: center;
  align-items: center;
}
.rpa {
  display: flex;
  padding: 90px 10px 90px 10px;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
}
.rpainfo button {
  display: flex;
  height: 60px;
  padding: 10px 30px;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 15px;
  border: 1px solid var(--Dark, #0f1524);
  background: var(--White, #fff);
  box-shadow: 2px 5px 0px 0px var(--Dark, #0f1524);
}
.rparow h4 {
  color: #333;
  font-family: "Roboto Slab", sans-serif;
  font-size: 30px;
  font-weight: 500;
  margin: 0px !important;
  padding-bottom: 10px;
}
.rparow p {
  color: #333;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  text-align: justify;
}
.rparow1 {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: -13px;
}
.rpasubinfo1 {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: -13px;
  margin-bottom: 20px;
}
.rpainfo1,
.sapibpcolor {
  background-color: #ddedff;
}
.sap {
  display: flex;
  padding: 100px 0px 30px 0px;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
}
.saprow {
  display: flex;
  align-items: flex-start;
}
.saprow {
  display: flex;
  align-items: flex-start;
}

.sapcards {
  display: flex;
  /* padding: 30px 0px; */
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.square {
  height: 90px;
  width: 90px;
  background-color: #555;
  transform: rotate(45deg);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.square p {
  transform: rotate(-45deg);
  margin: 0;
  color: white;
}
.sapheading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 13px;
}
.sapheading h2 {
  color: #163365;
  text-align: center;


  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.sapheading p {
  color: var(--Dark, #080b15);
  text-align: center;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.contentStyle1 {
  color: var(--Dark, #0f1524);
  font-family: Lato;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.nameStyle1 {
  color: var(--Dark, #0f1524);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 146%;
}
.cardClass1 {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 20px;
}
.dd11 {
  padding: 17px 25px;
  background: var(--White, #f9fdff);
  box-shadow: 0px 18px 28.7px 0px rgba(10, 52, 72, 0.1);
}
.SAPIBP h2 {
  color: var(--Dark, #0f1524);
  text-align: center;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.sapibp h2 {

  text-align: center;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sapinfo1 {
  height: 138px;
  margin-bottom: 30px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: -13px;
  border-radius: 8px;
  border-radius: 8px;
  background: var(--White, #f9fdff);
}
.sapinfo1 h2 {
  color: #000;
  font-family: Montserrat;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.sapinfo p {
  color: #333;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  padding-right: 44px;
}
.sapinfo1 p {
  color: black;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.sapimg {
  display: flex;
  padding: 90px 0px;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.sapimg h2 {
  color: var(--Dark, #0f1524);
  text-align: center;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.sapimg img {
  max-width: 100%;
}
.engineering {
  background-color: #ddedff;
}
.saprow {
  display: flex;
  justify-content: center;
  align-items: center;
}
.saphanainfo1 {
  margin-bottom: 30px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: -13px;
  border-radius: 8px;
  border-radius: 8px;
  background-color: aliceblue;
}

.dotnet {
  display: flex;
  padding: 50px 0px 50px 0px;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}


.dotnet h2 {

  text-align: center;
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.dotnet p {
  text-align: center;
  color: var(--Dark, #0f1524);
  text-align: center;

  font-size: 17px;
  font-style: normal;
  font-weight: 400;

}
.dotnetrow h2 {
  margin-left: 137px;
  color: var(--Dark, #0f1524);
  font-family: Montserrat;
  font-size: 55px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: Montserrat;
  font-size: 47px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.dotnet .card {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  background: linear-gradient(131deg, #dff5ff 11.38%, #f8fdff 35.41%);
  box-shadow: 0px 4px 30.9px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 30px;
  width: 100%;
}
.dotnetimg {
  height: 60px;
  width: 60px;
}
.dotnetinfo1 {
  min-height: 260px;
  margin-bottom: 30px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: -13px;
  border-radius: 8px;
  background-color: aliceblue;
}
.dotnetprocess1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dotnetprocess1 p {
  text-align: center;
}
.sapbtn {
  font-family: "Roboto";
  font-size: 18px;
  color: #f26836;
  /* margin-left: 40px; */
  display: flex;
  height: 60px;
  padding: 10px 30px;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  gap: 5px;
  border-radius: 15px;
  border: 1px solid #f26836;
  background: var(--White, #fff);
  box-shadow: none !important;
}
.sapsubinfo {
  margin-bottom: 10px;
  max-width: 100%;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: -13px;
  background: var(--White, #ddedff);
}
.sapsubinfo p {
  color: #333;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 146%;
}
.sapcards1 {
  background: #fff;
  border-radius: 10px;
  margin: 0 10px 40px;
  overflow: hidden;
  padding: 10px;
  position: relative;
  text-align: center;
  transition: all 0.3s ease-in-out;
  width: 100%;
}
.sapcards1:hover .reacttitle {
  color: rgb(195, 15, 15);
}

.sapcards1:hover {
  position: relative;
  /* z-index: 1;  */
  transform: scale(1.13);
}
.businessspan {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  padding: 20px;
}
.businessimg {
  height: 90px;
  width: 90px;
}
.engineeringinfo {
  min-height: 178px;
  margin-bottom: 30px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  /* align-items: flex-start; */
  gap: -13px;
  border-radius: 8px;
  background-color: #ddedff;
}
.Life {
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
.life,
.life1 {
  display: flex;
  padding: 90px 0px;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.life1 h4 {
  text-align: center;
}
.life h2 {
  text-align: center;
  color: var(--Dark, #0f1524);
  font-family: Montserrat;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.life h4 {
  text-align: center;
  color: var(--Dark, #0f1524);
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.life p {
  color: var(--Dark, #0f1524);
  font-family: Lato;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 146%;
  text-align: center;
}
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Hide the images by default */
.mySlides {
  display: none;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active,
.dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.crdimg {
  max-width: 100%;
  position: relative;
}
.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 2rem;
  color: white;
  cursor: pointer;
  z-index: 1;
}

.custom-arrow[disabled] {
  display: none;
}

.custom-arrow:nth-of-type(1) {
  left: 10px;
}

.custom-arrow:nth-of-type(2) {
  right: 10px;
}

.crdimg {
  max-width: 900px;
  border-radius: 20px;
  display: flex;
}
.life .card-text {
  font-size: 20px;
}
.lifecard .card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 50px;
  border-radius: 30px;
  background: var(--White, #fff);
  box-shadow: 0px 0px 22.2px 0px rgba(95, 178, 255);
}
.lifes {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 50px;
  border-radius: 30px;
  background: var(--White, #fff);
  box-shadow: 0px 0px 22.2px 0px rgba(95, 178, 255);
}
.lifeimg {
  height: 25rem;
  width: 26rem;
}

.carrier-container {
  position: relative;
  max-width: 100%;
  height: 600px;
  overflow: hidden;
}
.carrier-container::before,
.product-list12::before {
  content: "";
  margin: 0;
  padding: 0;
  display: none; /* or remove the unnecessary styles */
}
.jobs {
  text-align: center;
  font-size: 45px;
  font-family: "Lato";
}

/* Apply background image */
.carrierimg {
  background-image: url("./Images/about.png");
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  position: relative;
}

/* Black overlay */
.carrierimg::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
}
.product-list12 .card-title {
  margin: 0px !important;
  font-weight: 400;
  text-align: center;
  font-family: "Roboto Slab", serif;
  letter-spacing: 0px;
  color: #163365;
  opacity: 1;
  font-size: 18px !important;
  padding-top: 0px !important;
}
.product-list12 ::before {
  content: attr();
}
.cardhead .btn1 {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
}
.carrier-text {
  position: absolute;
  color: white;
  font-size: 72px;
  font-weight: bold;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.requirements {
  display: flex;
  padding: 0px 90px 0px;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.card-text1 {
  font-weight: 100;
  font-family: Lato;
  gap: 10px;
  color: #000000;
  font-size: 16px;
  text-align: left;
  display: flex;
}
.card-text1 svg {
  margin-right: 8px; /* Adjust the spacing between the icon and text */
  color: #eda606; /* Set the color of the icons to yellow */
}

header {
  text-align: center;
  margin-bottom: 20px;
}

header h1 {
  font-size: 2.5em;
  color: #0073e6;
} /* 
section{
  background-color: #f9f9f9;
  border-radius: 5px;
   margin-bottom: 20px;
  padding: 20px;
}
section:hover {
  margin-bottom: 20px;
  padding: 20px;
  border-left: 4px solid #0073e6;
  background-color: #f9f9f9;
  border-radius: 5px;
  color: #0073e6;
} */

section {
  margin-bottom: 20px;
  padding: 20px;
  border-left: 4px solid #0073e6;
  background-color: white;
  border-radius: 5px;
  transition: background-color 0.3s, border-color 0.3s;
}

section:hover {
  border-color: #580de2;
}

section h2 {
  font-size: 1.75em;
  color: black;
  margin-bottom: 10px;
  transition: color 0.3s;
}

/* New hover effect for h2 */
section:hover h3 {
  color: #005bb5;
}
section:hover p {
  color: #005bb5;
}
h2 {
  font-family: "ROBOTO SLAB";
  color: #163365;
  /* color: var(--Dark, #0F1524); */
  /* text-align: center; */
  /* font-family: Montserrat; */
  font-size:48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px !important;
  /* padding-bottom: 100px;
}

p, ul, ol {
  /* font-size: 1em;
  line-height: 1.6; */
}

ul,
ol {
  padding-left: 20px;
}

/* ul li, ol li {
  margin-bottom: 10px;
} */
section:hover ul li {
  color: #005bb5;
}
strong {
  color: black;
}
section:hover strong {
  color: #005bb5;
}
.nativerow h3 {
  font-size: 40px;
}
.process {
  display: flex;
  padding: 50px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.close-button:hover {
  color: #ff0000;
}

.modal-body {
  padding: 10px 20px 0px 20px;
}

.modal-body h2 {
  margin-top: 0;
  font-size: 24px;
}

.modal-body form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.modal-body input[type="text"],
.modal-body input[type="tel"],
.modal-body input[type="file"],
.modal-body input[type="email"] .modal-body select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 12px;
}

.s-btn {
  background-color: #007bff;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
  margin-left: 0px !important;
  box-shadow: 2px 5px 0px 0px var(--Dark, #0f1524);
}

.s-btn:hover {
  background-color: #0056b3;
}

/* Desktop styles */
@media (min-width: 1024px) {
  /* .modal-content1 {
    width: 50%;
  } */

  .modal-body h2 {
    font-size: 28px;
  }

  .modal-body input[type="text"],
  .modal-body input[type="tel"],
  .modal-body input[type="file"],
  .modal-body select {
    font-size: 18px;
  }

  .s-btn {
    font-size: 18px;
  }
}

/* Tablet styles */
@media (min-width: 768px) and (max-width: 1023px) {
  /* .modal-content1{
    width: 70%;
  } */

  .modal-body h2 {
    font-size: 26px;
  }

  .modal-body input[type="text"],
  .modal-body input[type="tel"],
  .modal-body input[type="file"],
  .modal-body select {
    font-size: 17px;
  }

  .s-btn {
    font-size: 17px;
  }
}

/* Mobile styles */
@media (max-width: 767px) {
  /* .modal-content1 {
    width: 90%;
  } */

  .modal-body h2 {
    font-size: 24px;
  }

  .modal-body input[type="text"],
  .modal-body input[type="tel"],
  .modal-body input[type="file"],
  .modal-body select {
    font-size: 16px;
  }

  .s-btn {
    font-size: 16px;
  }
}

/* Custom styling for dropdown items to ensure consistent font size */
/* ul, ol {
  font-style:oblique ;
  margin-bottom: 0;
  font-weight: 600;
  text-align: center;
  padding: 10px;
  font-size: 1em;
  line-height: 1.6;
} */
/* life.jsx CSS styling*/
.lifecard h5 {
  font-style: oblique;
  margin-bottom: 0;
  font-weight: 600;
  text-align: center;
  padding: 10px;
  font-size: 1em;
  line-height: 1.6;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 8px;
}

.grid-item {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.grid-item.middle-item {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  transform: scale(1.1);
}

.lifecard {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.lifeimg {
  max-width: 100%;
  height: auto;
  display: block;
  transition: transform 0.2s ease-in-out;
}

.lifeimg:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

@media (max-width: 100%) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }
  .grid-item.middle-item {
    grid-column: 1 / span 2;
    grid-row: auto;
  }
}

@media (max-width: 100%) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
  .grid-item.middle-item {
    grid-column: auto;
    grid-row: auto;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 10px;
  }
  .lifecard {
    padding: 5px;
  }
}
/* Add to your App.css or relevant CSS file */

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  max-width: 90%;
  width: 800px;
  outline: none;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

/* .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
} */

.carousel-image {
  text-align: center;
}

.carousel-image img {
  max-width: 100%;
  height: auto;
}

.carousel-text {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  color: #333;
}

.fluid-container {
  width: 100%;
  padding: 0;
}

.life1 .grid-container {
  display: grid;
  gap: 20px;
}

.life1 .grid-item {
  cursor: pointer;
}

.lifecard {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lifeimg {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.lifeimg-text {
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  color: #333;
}

.androidframe {
  display: flex;
  justify-content: space-between;
}

.content-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 60%; /* Adjust as needed for your layout */
}

.CaptioN0 {
  flex: 1 1 45%; /* Adjust to control the card width */
  display: flex;
  margin: 10px;
}

.CaptioN0 img {
  margin-right: 10px;
}

.androidinfo {
  flex: 1;
}

.images-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 35%; /* Adjust as needed for your layout */
}

.images-container img {
  margin: 10px 0;
  width: 100%; /* Adjust or remove for different image sizes */
}
.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: rgba(236, 222, 227, 0.9); /* Translucent white */
  padding: 10px;
  border-radius: 6px;
  max-width: 90%;
  width: 70%;
  height: auto; /* Adjust height to fit the screen better */
  outline: none;
  overflow: hidden; /* Prevent overflow */
}

.Overlay {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.close-button {
  /* position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.4rem; */
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.carousel-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
}

.carousel-image img {
  max-width: 100%;
  max-height: 60%;
  height: auto;
  border-radius: 4px;
}

.carousel-text {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  color: #333;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 8px;
}

.grid-item {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.grid-item.middle-item {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  transform: scale(1);
}

.lifecard {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.lifeimg {
  max-width: 100%;
  height: auto;
  display: block;
  transition: transform 0.2s ease-in-out;
}

.lifeimg:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
}
.search-box {
  position: relative;
}

.search-box input[type="text"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 15px;
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  width: 100%;
  max-height: 200px; /* Limit max height as needed */
  overflow-y: auto;
  border: 1px solid #ccc;
  background-color: #fff;
}

.search-results div {
  padding: 10px;
}

.search-results div:hover {
  background-color: #f0f0f0;
}
.modal-dialog {
  justify-content: center;
}
.ModalImage {
  width: 100% !important;
}

/* Example CSS for static job cards */
.job-card {
  height: 357px;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.job-card-logo {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.job-card-details {
  flex: 1;
}

.job-card-details h3 {
  font-size: 25px;
  color: #163365;
  text-align: center;
}

.job-card-details p {
  font-size: 16px;
  margin: 5px 0;
}
.job-card-details p svg {
  color: #eda606;
  font-size: 22px;
  height: 20px;
  padding-right: 8px;
}

.job-card-details button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.job-card-details button:hover {
  background-color: #0056b3;
}
.customs-button {
  gap: 10px;
  margin-left: 25%;
  text-align: center;
  height: 40px;
  font-size: 18px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #f26836;
  color: #f26836;
  opacity: 1;
  padding: 8px 20px;
}
.customs-button:hover {
  gap: 10px;
  text-align: center;
  height: 40px;
  font-size: 18px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #f26836;
  color: #f26836;
  opacity: 1;
  padding: 8px 20px;
}

@media (max-width: 576px) {
  .Aboutus {
    padding-top: 100px;
  }
  .aboutusrow .col-md-8 {
    padding: 0px;
  }
  .aboutusrow p {
    text-align: center;
    padding-top: 20px;
  }
  .abtus {
    width: 100%;
  }
  .nativerow h2 {
    font-size: 35px !important; /* Adjust heading size for smaller screens */
  }
  .nativerow img {
    padding-top: 50px;

    margin-bottom: 30px;
  
  }
  .sapinfo h2 {
    font-size: 35px !important;
  }
  .sapinfo p {
    padding: 0px !important;
  }
  .sapinfo img {
    width: 100%;
    padding-top: 50px !important;
  }
  .sapinfo p {
    text-align: center !important;
  }
  .androidinfo {
    display: block !important;
  }
  .androidinfo h4 {
    font-size: 25px !important;
  }
  .CaptioN0 p {
    padding: 0px 0px 0px 20px !important;
    margin: 0px !important;
    text-align: center !important;
  }
  .Content p {
    text-align: center;
  }
  .aboutusrow h2 {
    font-size: 35px !important;
  }
  .android h2,
  .php h2 {
    font-size: 35px !important;
  }
  .sapsubinfo h2 {
    font-size: 25px !important;
  }
  .info1 {
    height: auto;
  }
  .strategy h2 {
    font-size: 35px;
    text-align: center !important;
  }
  .head1 h2 {
    font-size: 25px !important;
  }
  .contact-header {
    text-align: center !important;
  }
  .footer-section1 {
    padding: 0px;
  }

  .Content h2 {
    font-size: 35px;
  }
  .key-challenges h3 {
    font-size: 18px;
  }
  .noderow h2 {
    font-size: 35px !important;
  }
  .nodecolor h2 {
    font-size: 35px !important;
  }
  .native h2 {
    font-size: 35px !important;
  }
  .angularframe {
    margin: 0px !important;
  }
  .angularcontent {
    width: 300px !important;
  }
  .angularframe1,
  .angularframe {
    gap: 0px !important;
  }
  .nodecolor1 h2 {
    font-size: 35px !important;
  }
  .nodeinfo {
    height: auto !important;
  }
  .nodecontent {
    display: block !important;
  }
  .nodespan1 {
    display: none;
  }
  .nativerow h2,
  .javarow h2 {
    font-size: 35px !important;
  }
  .process h2 {
    font-size: 35px !important;
  }
  .java1 h2 {
    font-size: 35px !important;
  }
  .java img {
    padding-top: 100px !important;
  }
  .java1 p {
    padding: 0px !important;
  }
  .javabenifits h2 {
    font-size: 35px !important;
  }
  .javainfo1 {
    height: auto !important;
  }
  .subinfo1 h2 {
    font-size: 25px !important;
  }
  .nativecolor p {
    padding: 0px !important;
  }
  .native img {
    width: 100%;
  }
  .accordion p {
    font-size: 16px !important;
  }
  .dotnetinfo1 {
    height: auto !important;
  }
  .dotnet .card {
    padding: 0px !important;
  }
  .reactcontainer h2 {
    font-size: 35px !important;
  }
  .careerrow .col-md-5 {
    margin-top: 15px;
  }
  .careerrow .col-md-2 {
    margin-top: 15px;
  }
  .search-form {
    flex-direction: column; /* Stack items vertically */
    gap: 15px; /* Add spacing between stacked items */
  }

  .search-bar,
  .search-button {
    width: 100%; /* Full width for both the input and button */
  }
}
.blogwrapper {
  margin-top: 40px !important;
}
.blogwrapper .row {
  justify-content: left;
}
.blogwrapper h5 {
  text-align: left;
  font-size: 20px !important;
  font-weight: 600;
  min-height: 50px;
}
.blogwrapper .card-title a {
  display: block;
}
.blogwrapper .card-text {
  text-align: left;
  padding: 10px 20px 0px 0;
  font-size: 16px;
}
.datewrapper {
  font-size: 16px;
  margin-bottom: 16px;
}
.blogwrapper .card,
.blogwrapper .card-footer {
  background: #f9f9f9;
}
.blogwrapper .card-footer .text-muted {
  font-size: 16px;
  margin-bottom: 16px;
}

.blogpage .form-control {
  font-size: 16px;
}
.select_Category {
  font-size: 14px;
  font-weight: 600;
  margin: 0px 0 0 0;
}

.select_Category + select {
  font-size: 14px;
}
.srch {
  font-size: 18px;
}
.blogpage h1 {
  font-weight: 600;
}
.breadcrumb > li {
  font-size: 14px !important;
}
.hovertitle {
  height: 3rem; /* Adjust height for two lines */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Show ellipsis for overflowing text */
  display: -webkit-box; /* Enable multiline ellipsis */
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical; /* Vertical box orientation */
}
.rightWrapper .card {
  background: #f9f9f9 !important;
  margin-bottom: 25px !important;
}
.blogtagpagecss h3 {
  font-size: 40px;
  margin-top: 30px;
}
.rightWrapper .card-body {
  padding: 15px;
  text-align: center;
}
.social_icon button {
  margin-right: 10px;
  margin-top: 10px;
}
.rightWrapper h6 {
  font-size: 16px;
}
.coomentbox .btn {
  padding: 10px;
  font-size: 15px;
  margin: lauto;
}
.detailsLeft h1 {
  font-weight: 600;
}
.detailsLeft p {
  padding: 10px 0;
}
.blogwrapper img {
  max-height: 200px;
  min-height: 200px;
}
.detailsLeft h2 {
  font-size: 16px;
  color: #333333;
}
.detailsLeft img {
  width: 100%;
  margin: 15px 0px !important;
}
.commentbox button {
  margin: auto;
  padding: 8px;
  font-size: 15px;
}
.publish_wrap {
  color: #0094db !important;
  font-weight: 500;
}
.hovertitle :hover {
  color: #0094db !important;
}
/* css for blog */

@media screen and (max-width: 680px) {
  .Categories_wrapper {
    display: none;
  }
  .searchcatgory .w-50 {
    width: 100% !important;
  }
  .searchcatgory {
    margin-top: 20px;
  }
  .datewrapper {
    text-align: left;
    margin-top: 10px;
  }
  .detailsLeft {
    text-align: left;
  }
  .detailsLeft h2,
  .detailsLeft p {
    text-align: left;
  }
}



