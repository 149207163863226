.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content1 {
  margin-top: 100px;
  margin-bottom: 100px;
  background: white; 
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  font-size: 14px; 
  z-index: 1010;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h2 {
  margin: 0 auto;
  font-size: 18px;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-body {
  /* margin-top: 20px; */
  padding: 10px 20px 0px 20px;
}

.modal-body form label {
  display: block;
  margin-bottom: 5px;
}

.modal-body form input[type="text"],
.modal-body form input[type="tel"],
.modal-body form input[type="file"],
.modal-body form input[type="email"],
.modal-body form .selectOption {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 12px;
}

.s-btn {
  background-color: #007BFF;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 14px;
  width: 100%;
}

.s-btn:hover {
  background-color: #0056b3;
}