/* Blog.css */
.blog-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    font-family: "Roboto", sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #fff;
}



.blog-section {
    margin-bottom: 3rem;
    padding: 1.5rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.blog-section h3 {
    color: #3498db;
    font-size: 1.8rem;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid #3498db;
}

.blog-section p {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: #444;
    line-height: 1.8;
}

.blog-image {
    max-width: 100%;
    height: auto;
    margin: 1.5rem 0;
    border-radius: 6px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.blog-list {
    margin: 1rem 0;
    font-size: 1.5rem;
    padding-left: 2rem;
}

.blog-list li {
    margin-bottom: 0.8rem;
    padding-left: 0.5rem;
}

.code-snippet {
    font-family: "Roboto", sans-serif;
    background-color: #f4f4f4;
    padding: 0.2rem 0.4rem;
    border-radius: 3px;
    font-size: 1.5rem;
}

.highlight {
    color: #e74c3c;
    font-weight: 500;
}

@media (max-width: 768px) {
    .blog-container {
        padding: 1rem;
    }
    
    .blog-section h3 {
        font-size: 1.5rem;
    }
    
    .blog-section p {
        font-size: 1rem;
    }
}

@media (max-width: 700px) {

    .blog-section li{ font-size: 16px; text-align: left;}
    .blog-section p{ font-size: 16px; text-align: left;}
}