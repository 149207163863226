/* .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    font-size: 14px; 
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-header h2 {
    margin: 0 auto;
    font-size: 18px;
  }
  
  .close-button {
    background: none !important;
    border: none !important;
    font-size: 24px;
    cursor: pointer;
  }
  
  .modal-body {
    padding: 10px 20px 0px 20px;
  }
  
  .modal-body form label {
    display: block;
    margin-bottom: 5px;
  }
  
  .modal-body form input[type="text"],
  .modal-body form input[type="tel"],
  .modal-body form input[type="file"],
  .modal-body form input[type="email"],
  .modal-body form .selectOption {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 12px;
  }
  
  .batan {
    background-color: #007BFF !important;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    font-size: 14px;
    width: 100%;
  }
  
  .batan:hover {
    background-color: #0056b3!important;
  /* } */
  /* .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    font-size: 14px; 
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-header h2 {
    margin: 0 auto;
    font-size: 18px;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .modal-body {
    padding: 10px 20px 0px 20px;
  }
  
  .modal-body form label {
    display: block;
    margin-bottom: 5px;
  }
  
  .modal-body form input[type="text"],
  .modal-body form input[type="tel"],
  .modal-body form input[type="file"],
  .modal-body form input[type="email"],
  .modal-body form .selectOption {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 12px;
  }
  
  .s-btn {
    background-color: #007BFF;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    font-size: 14px;
  }
  
  .s-btn:hover {
    background-color: #0056b3;
  }  */
    /* Modal1.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content1 {
    background: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    font-size: 14px;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .modal-header h2 {
    margin: 0;
    font-size: 18px;
    text-align: center;
    flex-grow: 1;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    margin-left: auto; /* Pushes the close button to the right */
  }
  
  .modal-body {
    padding: 10px 20px 0px 20px;
  }
  
  .modal-body form label {
    display: block;
    margin-bottom: 5px;
  }
  
  .modal-body form input[type="text"],
  .modal-body form input[type="tel"],
  .modal-body form input[type="email"],
  .modal-body form .selectOption,
  .modal-body form input[type="file"],
  .modal-body form textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 12px;
    box-sizing: border-box;
    height: 40px; /* Set a uniform height for all inputs */
  }
  
  .modal-body form textarea {
    resize: vertical; /* Allow vertical resizing */
    height: 40px; /* Match height with other input fields */
  }
  
  .s-btn {
    background-color: #007BFF;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    font-size: 14px;
  }
  
  .s-btn:hover {
    background-color: #0056b3;
  }
  